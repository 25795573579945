import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../../config/store';

export interface Customer {
  customerId: string;
  customerName: string;
  customerLogo: string;
}

export interface RedirectSettings {
  redirectUri: string;
  includeUser: boolean;
}
export interface EndpointConfig {
  customer: Customer;
  endpointId: string;
  redirectSettings?: RedirectSettings;
  vspecs: VspecEndpoint[];
}

export type VspecEndpoint = {
  vspecId: string;
  vsku: VowVsku;
  type?: VSkuType;
};

export interface EndpointState {
  isLoading: boolean;
  endpointLoaded: boolean;
  endpointId: string;
  customer: Customer;
  redirectSettings?: RedirectSettings;
  errorMessage: string;
  vspecs: VspecEndpoint[];
}

const initialState: EndpointState = {
  isLoading: true,
  endpointLoaded: false,
  endpointId: '',
  errorMessage: '',
  customer: {
    customerId: '',
    customerName: '',
    customerLogo: '',
  },
  vspecs: [],
};

type VskuField = {
  fieldName: string;
  nameId: string;
  errorId: string;
  label: string;
  isPrimary: boolean;
  isRequired: boolean;
  isDerived: boolean;
  dataType: string;
  options: {
    values?: {
      value: string;
      label: string;
      valueStringId: string;
      labelStringId: string;
    }[];
  };
};

export type VowVsku = FieldVSku | DocumentVSku;
export enum VSkuType {
  field,
  document,
}

export type FieldVSku = {
  vsku: string;
  type: VSkuType.field;
  nameId: string;
  jurisdiction: string;
  titleId: string;
  descriptionId: string;
  inputClaims: VskuField[];
  outputResults: VskuField[];
  allowMultiples: boolean;
  requiredInputs: VskuField[];
  requiredOutputs: VskuField[];
  inputCategories: VskuField[];
  primaryInputs: string[];
};

export type DocumentVSku = {
  vsku: string;
  type: VSkuType.document;
  nameId: string;
  titleId: string;
  descriptionId: string;
  allowMultiples: boolean;
  jurisdiction: string;
};

export const endpointSlice = createSlice({
  name: 'endpoint',
  initialState,
  reducers: {
    startGetEndpoint: (state, action: PayloadAction<string>): void => {
      state.isLoading = true;
      state.endpointId = action.payload;
      state.endpointLoaded = false;
    },
    startGetEndpointByAlias: (state, action: PayloadAction<string>): void => {
      state.isLoading = true;
      state.endpointId = action.payload;
      state.endpointLoaded = false;
    },
    finishGetEndpoint: (state, action: PayloadAction<EndpointConfig>): void => {
      const { vspecs, redirectSettings, customer, endpointId } = action.payload;
      state.endpointLoaded = true;
      state.vspecs = vspecs;
      state.customer = customer;
      state.endpointId = endpointId;
      state.isLoading = false;
      state.redirectSettings = redirectSettings;
    },
    errorGetEndpoint: (state, action: PayloadAction<string>): void => {
      state.endpointLoaded = false;
      state.errorMessage = action.payload;
      state.isLoading = false;
    },
  },
});

export const {
  startGetEndpoint,
  startGetEndpointByAlias,
  finishGetEndpoint,
  errorGetEndpoint,
} = endpointSlice.actions;

export const selectEndpoint = (state: RootState) => state.endpoint;

export default endpointSlice.reducer;
