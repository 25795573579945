import React, {
  createContext,
  ReactNode,
  useState,
  useEffect,
  useContext,
} from 'react';
import config from '@utils/config';
import { v4 as uuid } from 'uuid';
import { ExperimentContextType } from './index';
import { SplitContext, SplitFactory } from '@splitsoftware/splitio-react';
const sdkConfig: SplitIO.IBrowserSettings = {
  core: {
    authorizationKey: config.splitApiKey,
    // Start with a random key value. When the user is logged in, update to their email address.
    key: uuid(),
  },
};

export const SplitExperimentContext =
  createContext<ExperimentContextType>(null);

const SplitExperimentProvider = ({ children }: { children: ReactNode }) => {
  const [initialized, setInitialized] = useState(false);
  const { isReady, client: defaultClient, factory } = useContext(SplitContext);
  const [client, setClient] = useState(defaultClient);
  useEffect(() => {
    if (isReady) {
      setInitialized(true);
    }
  }, [isReady]);

  const getExperiment = (experimentName: string) => {
    return client.getTreatment(experimentName);
  };

  const targetUser = ({ userId }: { userId: string }) => {
    setClient(factory.client(userId));
  };

  return (
    <SplitExperimentContext.Provider
      value={{ initialized, getExperiment, targetUser }}
    >
      {children}
    </SplitExperimentContext.Provider>
  );
};

export const SplitExperiment = ({ children }: { children: ReactNode }) => {
  return (
    <SplitFactory config={sdkConfig}>
      <SplitExperimentProvider>{children}</SplitExperimentProvider>
    </SplitFactory>
  );
};
