import { defineMessages } from 'react-intl';

// Define strings for the states
defineMessages({
  alabamaLabel: {
    id: 'schemas.stateList.alabama.label',
    description: 'Alabama Label',
    defaultMessage: 'Alabama',
  },
  alabamaValue: {
    id: 'schemas.stateList.alabama.value',
    description: 'Alabama Value',
    defaultMessage: 'AL',
  },
  alaskaLabel: {
    id: 'schemas.stateList.alaska.label',
    description: 'Alaska Label',
    defaultMessage: 'Alaska',
  },
  alaskaValue: {
    id: 'schemas.stateList.alaska.value',
    description: 'Alaska Value',
    defaultMessage: 'AK',
  },
  arizonaLabel: {
    id: 'schemas.stateList.arizona.label',
    description: 'Arizona Label',
    defaultMessage: 'Arizona',
  },
  arizonaValue: {
    id: 'schemas.stateList.arizona.value',
    description: 'Arizona Value',
    defaultMessage: 'AZ',
  },
  arkansasLabel: {
    id: 'schemas.stateList.arkansas.label',
    description: 'Arkansas Label',
    defaultMessage: 'Arkansas',
  },
  arkansasValue: {
    id: 'schemas.stateList.arkansas.value',
    description: 'Arkansas Value',
    defaultMessage: 'AR',
  },
  californiaLabel: {
    id: 'schemas.stateList.california.label',
    description: 'California Label',
    defaultMessage: 'California',
  },
  californiaValue: {
    id: 'schemas.stateList.california.value',
    description: 'California Value',
    defaultMessage: 'CA',
  },
  coloradoLabel: {
    id: 'schemas.stateList.colorado.label',
    description: 'Colorado Label',
    defaultMessage: 'Colorado',
  },
  coloradoValue: {
    id: 'schemas.stateList.colorado.value',
    description: 'Colorado Value',
    defaultMessage: 'CO',
  },
  connecticutLabel: {
    id: 'schemas.stateList.connecticut.label',
    description: 'Connecticut Label',
    defaultMessage: 'Connecticut',
  },
  connecticutValue: {
    id: 'schemas.stateList.connecticut.value',
    description: 'Connecticut Value',
    defaultMessage: 'CT',
  },
  delawareLabel: {
    id: 'schemas.stateList.delaware.label',
    description: 'Delaware Label',
    defaultMessage: 'Delaware',
  },
  delawareValue: {
    id: 'schemas.stateList.delaware.value',
    description: 'Delaware Value',
    defaultMessage: 'DE',
  },
  floridaLabel: {
    id: 'schemas.stateList.florida.label',
    description: 'Florida Label',
    defaultMessage: 'Florida',
  },
  floridaValue: {
    id: 'schemas.stateList.florida.value',
    description: 'Florida Value',
    defaultMessage: 'FL',
  },
  georgiaLabel: {
    id: 'schemas.stateList.georgia.label',
    description: 'Georgia Label',
    defaultMessage: 'Georgia',
  },
  georgiaValue: {
    id: 'schemas.stateList.georgia.value',
    description: 'Georgia Value',
    defaultMessage: 'GA',
  },
  hawaiiLabel: {
    id: 'schemas.stateList.hawaii.label',
    description: 'Hawaii Label',
    defaultMessage: 'Hawaii',
  },
  hawaiiValue: {
    id: 'schemas.stateList.hawaii.value',
    description: 'Hawaii Value',
    defaultMessage: 'HI',
  },
  idahoLabel: {
    id: 'schemas.stateList.idaho.label',
    description: 'Idaho Label',
    defaultMessage: 'Idaho',
  },
  idahoValue: {
    id: 'schemas.stateList.idaho.value',
    description: 'Idaho Value',
    defaultMessage: 'ID',
  },
  illinoisLabel: {
    id: 'schemas.stateList.illinois.label',
    description: 'Illinois Label',
    defaultMessage: 'Illinois',
  },
  illinoisValue: {
    id: 'schemas.stateList.illinois.value',
    description: 'Illinois Value',
    defaultMessage: 'IL',
  },
  indianaLabel: {
    id: 'schemas.stateList.indiana.label',
    description: 'Indiana Label',
    defaultMessage: 'Indiana',
  },
  indianaValue: {
    id: 'schemas.stateList.indiana.value',
    description: 'Indiana Value',
    defaultMessage: 'IN',
  },
  iowaLabel: {
    id: 'schemas.stateList.iowa.label',
    description: 'Iowa Label',
    defaultMessage: 'Iowa',
  },
  iowaValue: {
    id: 'schemas.stateList.iowa.value',
    description: 'Iowa Value',
    defaultMessage: 'IA',
  },
  kansasLabel: {
    id: 'schemas.stateList.kansas.label',
    description: 'Kansas Label',
    defaultMessage: 'Kansas',
  },
  kansasValue: {
    id: 'schemas.stateList.kansas.value',
    description: 'Kansas Value',
    defaultMessage: 'KS',
  },
  kentuckyLabel: {
    id: 'schemas.stateList.kentucky.label',
    description: 'Kentucky Label',
    defaultMessage: 'Kentucky',
  },
  kentuckyValue: {
    id: 'schemas.stateList.kentucky.value',
    description: 'Kentucky Value',
    defaultMessage: 'KY',
  },
  louisianaLabel: {
    id: 'schemas.stateList.louisiana.label',
    description: 'Louisiana Label',
    defaultMessage: 'Louisiana',
  },
  louisianaValue: {
    id: 'schemas.stateList.louisiana.value',
    description: 'Louisiana Value',
    defaultMessage: 'LA',
  },
  maineLabel: {
    id: 'schemas.stateList.maine.label',
    description: 'Maine Label',
    defaultMessage: 'Maine',
  },
  maineValue: {
    id: 'schemas.stateList.maine.value',
    description: 'Maine Value',
    defaultMessage: 'ME',
  },
  marylandLabel: {
    id: 'schemas.stateList.maryland.label',
    description: 'Maryland Label',
    defaultMessage: 'Maryland',
  },
  marylandValue: {
    id: 'schemas.stateList.maryland.value',
    description: 'Maryland Value',
    defaultMessage: 'MD',
  },
  massachusettsLabel: {
    id: 'schemas.stateList.massachusetts.label',
    description: 'Massachusetts Label',
    defaultMessage: 'Massachusetts',
  },
  massachusettsValue: {
    id: 'schemas.stateList.massachusetts.value',
    description: 'Massachusetts Value',
    defaultMessage: 'MA',
  },
  michiganLabel: {
    id: 'schemas.stateList.michigan.label',
    description: 'Michigan Label',
    defaultMessage: 'Michigan',
  },
  michiganValue: {
    id: 'schemas.stateList.michigan.value',
    description: 'Michigan Value',
    defaultMessage: 'MI',
  },
  minnesotaLabel: {
    id: 'schemas.stateList.minnesota.label',
    description: 'Minnesota Label',
    defaultMessage: 'Minnesota',
  },
  minnesotaValue: {
    id: 'schemas.stateList.minnesota.value',
    description: 'Minnesota Value',
    defaultMessage: 'MN',
  },
  mississippiLabel: {
    id: 'schemas.stateList.mississippi.label',
    description: 'Mississippi Label',
    defaultMessage: 'Mississippi',
  },
  mississippiValue: {
    id: 'schemas.stateList.mississippi.value',
    description: 'Mississippi Value',
    defaultMessage: 'MS',
  },
  missouriLabel: {
    id: 'schemas.stateList.missouri.label',
    description: 'Missouri Label',
    defaultMessage: 'Missouri',
  },
  missouriValue: {
    id: 'schemas.stateList.missouri.value',
    description: 'Missouri Value',
    defaultMessage: 'MO',
  },
  montanaLabel: {
    id: 'schemas.stateList.montana.label',
    description: 'Montana Label',
    defaultMessage: 'Montana',
  },
  montanaValue: {
    id: 'schemas.stateList.montana.value',
    description: 'Montana Value',
    defaultMessage: 'MT',
  },
  nebraskaLabel: {
    id: 'schemas.stateList.nebraska.label',
    description: 'Nebraska Label',
    defaultMessage: 'Nebraska',
  },
  nebraskaValue: {
    id: 'schemas.stateList.nebraska.value',
    description: 'Nebraska Value',
    defaultMessage: 'NE',
  },
  nevadaLabel: {
    id: 'schemas.stateList.nevada.label',
    description: 'Nevada Label',
    defaultMessage: 'Nevada',
  },
  nevadaValue: {
    id: 'schemas.stateList.nevada.value',
    description: 'Nevada Value',
    defaultMessage: 'NV',
  },
  newJerseyLabel: {
    id: 'schemas.stateList.newJersey.label',
    description: 'New Jersey Label',
    defaultMessage: 'New Jersey',
  },
  newJerseyValue: {
    id: 'schemas.stateList.newJersey.value',
    description: 'New Jersey Value',
    defaultMessage: 'NJ',
  },
  newMexicoLabel: {
    id: 'schemas.stateList.newMexico.label',
    description: 'New Mexico Label',
    defaultMessage: 'New Mexico',
  },
  newMexicoValue: {
    id: 'schemas.stateList.newMexico.value',
    description: 'New Mexico Value',
    defaultMessage: 'NM',
  },
  newYorkLabel: {
    id: 'schemas.stateList.newYork.label',
    description: 'New York Label',
    defaultMessage: 'New York',
  },
  newYorkValue: {
    id: 'schemas.stateList.newYork.value',
    description: 'New York Value',
    defaultMessage: 'NY',
  },
  northCarolinaLabel: {
    id: 'schemas.stateList.northCarolina.label',
    description: 'North Carolina Label',
    defaultMessage: 'North Carolina',
  },
  northCarolinaValue: {
    id: 'schemas.stateList.northCarolina.value',
    description: 'North Carolina Value',
    defaultMessage: 'NC',
  },
  northDakotaLabel: {
    id: 'schemas.stateList.northDakota.label',
    description: 'North Dakota Label',
    defaultMessage: 'North Dakota',
  },
  northDakotaValue: {
    id: 'schemas.stateList.northDakota.value',
    description: 'North Dakota Value',
    defaultMessage: 'ND',
  },
  ohioLabel: {
    id: 'schemas.stateList.ohio.label',
    description: 'Ohio Label',
    defaultMessage: 'Ohio',
  },
  ohioValue: {
    id: 'schemas.stateList.ohio.value',
    description: 'Ohio Value',
    defaultMessage: 'OH',
  },
  oklahomaLabel: {
    id: 'schemas.stateList.oklahoma.label',
    description: 'Oklahoma Label',
    defaultMessage: 'Oklahoma',
  },
  oklahomaValue: {
    id: 'schemas.stateList.oklahoma.value',
    description: 'Oklahoma Value',
    defaultMessage: 'OK',
  },
  oregonLabel: {
    id: 'schemas.stateList.oregon.label',
    description: 'Oregon Label',
    defaultMessage: 'Oregon',
  },
  oregonValue: {
    id: 'schemas.stateList.oregon.value',
    description: 'Oregon Value',
    defaultMessage: 'OR',
  },
  pennsylvaniaLabel: {
    id: 'schemas.stateList.pennsylvania.label',
    description: 'Pennsylvania Label',
    defaultMessage: 'Pennsylvania',
  },
  pennsylvaniaValue: {
    id: 'schemas.stateList.pennsylvania.value',
    description: 'Pennsylvania Value',
    defaultMessage: 'PA',
  },
  rhodeIslandLabel: {
    id: 'schemas.stateList.rhodeIsland.label',
    description: 'Rhode Island Label',
    defaultMessage: 'Rhode Island',
  },
  rhodeIslandValue: {
    id: 'schemas.stateList.rhodeIsland.value',
    description: 'Rhode Island Value',
    defaultMessage: 'RI',
  },
  southCarolinaLabel: {
    id: 'schemas.stateList.southCarolina.label',
    description: 'South Carolina Label',
    defaultMessage: 'South Carolina',
  },
  southCarolinaValue: {
    id: 'schemas.stateList.southCarolina.value',
    description: 'South Carolina Value',
    defaultMessage: 'SC',
  },
  southDakotaLabel: {
    id: 'schemas.stateList.southDakota.label',
    description: 'South Dakota Label',
    defaultMessage: 'South Dakota',
  },
  southDakotaValue: {
    id: 'schemas.stateList.southDakota.value',
    description: 'South Dakota Value',
    defaultMessage: 'SD',
  },
  tennesseeLabel: {
    id: 'schemas.stateList.tennessee.label',
    description: 'Tennessee Label',
    defaultMessage: 'Tennessee',
  },
  tennesseeValue: {
    id: 'schemas.stateList.tennessee.value',
    description: 'Tennessee Value',
    defaultMessage: 'TN',
  },
  texasLabel: {
    id: 'schemas.stateList.texas.label',
    description: 'Texas Label',
    defaultMessage: 'Texas',
  },
  texasValue: {
    id: 'schemas.stateList.texas.value',
    description: 'Texas Value',
    defaultMessage: 'TX',
  },
  utahLabel: {
    id: 'schemas.stateList.utah.label',
    description: 'Utah Label',
    defaultMessage: 'Utah',
  },
  utahValue: {
    id: 'schemas.stateList.utah.value',
    description: 'Utah Value',
    defaultMessage: 'UT',
  },
  vermontLabel: {
    id: 'schemas.stateList.vermont.label',
    description: 'Vermont Label',
    defaultMessage: 'Vermont',
  },
  vermontValue: {
    id: 'schemas.stateList.vermont.value',
    description: 'Vermont Value',
    defaultMessage: 'VT',
  },
  virginiaLabel: {
    id: 'schemas.stateList.virginia.label',
    description: 'Virginia Label',
    defaultMessage: 'Virginia',
  },
  virginiaValue: {
    id: 'schemas.stateList.virginia.value',
    description: 'Virginia Value',
    defaultMessage: 'VA',
  },
  washingtonLabel: {
    id: 'schemas.stateList.washington.label',
    description: 'Washington Label',
    defaultMessage: 'Washington',
  },
  washingtonValue: {
    id: 'schemas.stateList.washington.value',
    description: 'Washington Value',
    defaultMessage: 'WA',
  },
  westVirginiaLabel: {
    id: 'schemas.stateList.westVirginia.label',
    description: 'West Virginia Label',
    defaultMessage: 'West Virginia',
  },
  westVirginiaValue: {
    id: 'schemas.stateList.westVirginia.value',
    description: 'West Virginia Value',
    defaultMessage: 'WV',
  },
  wisconsinLabel: {
    id: 'schemas.stateList.wisconsin.label',
    description: 'Wisconsin Label',
    defaultMessage: 'Wisconsin',
  },
  wisconsinValue: {
    id: 'schemas.stateList.wisconsin.value',
    description: 'Wisconsin Value',
    defaultMessage: 'WI',
  },
  wyomingLabel: {
    id: 'schemas.stateList.wyoming.label',
    description: 'Wyoming Label',
    defaultMessage: 'Wyoming',
  },
  wyomingValue: {
    id: 'schemas.stateList.wyoming.value',
    description: 'Wyoming Value',
    defaultMessage: 'WY',
  },
});

export const stateList = [
  'schemas.stateList.alabama',
  'schemas.stateList.alaska',
  'schemas.stateList.arizona',
  'schemas.stateList.arkansas',
  'schemas.stateList.california',
  'schemas.stateList.colorado',
  'schemas.stateList.connecticut',
  'schemas.stateList.delaware',
  'schemas.stateList.florida',
  'schemas.stateList.georgia',
  'schemas.stateList.hawaii',
  'schemas.stateList.idaho',
  'schemas.stateList.illinois',
  'schemas.stateList.indiana',
  'schemas.stateList.iowa',
  'schemas.stateList.kansas',
  'schemas.stateList.kentucky',
  'schemas.stateList.louisiana',
  'schemas.stateList.maine',
  'schemas.stateList.maryland',
  'schemas.stateList.massachusetts',
  'schemas.stateList.michigan',
  'schemas.stateList.minnesota',
  'schemas.stateList.mississippi',
  'schemas.stateList.missouri',
  'schemas.stateList.montana',
  'schemas.stateList.nebraska',
  'schemas.stateList.nevada',
  'schemas.stateList.newJersey',
  'schemas.stateList.newMexico',
  'schemas.stateList.newYork',
  'schemas.stateList.northCarolina',
  'schemas.stateList.northDakota',
  'schemas.stateList.ohio',
  'schemas.stateList.oklahoma',
  'schemas.stateList.oregon',
  'schemas.stateList.pennsylvania',
  'schemas.stateList.rhodeIsland',
  'schemas.stateList.southCarolina',
  'schemas.stateList.southDakota',
  'schemas.stateList.tennessee',
  'schemas.stateList.texas',
  'schemas.stateList.utah',
  'schemas.stateList.vermont',
  'schemas.stateList.virginia',
  'schemas.stateList.washington',
  'schemas.stateList.westVirginia',
  'schemas.stateList.wisconsin',
  'schemas.stateList.wyoming',
];

// Define strings for license Types
defineMessages({
  professionalBeautyLabel: {
    id: 'schemas.licenseTypes.professionalBeauty.label',
    description: 'professionalBeauty Label',
    defaultMessage: 'Professional Beauty',
  },
  professionalBeautyValue: {
    id: 'schemas.licenseTypes.professionalBeauty.value',
    description: 'professionalBeauty Value',
    defaultMessage: 'professionalBeauty',
  },

  homeServicesLabel: {
    id: 'schemas.licenseTypes.homeServices.label',
    description: 'homeServices Label',
    defaultMessage: 'Home Services',
  },
  homeServicesValue: {
    id: 'schemas.licenseTypes.homeServices.value',
    description: 'homeServices Value',
    defaultMessage: 'homeServices',
  },

  architectureLabel: {
    id: 'schemas.licenseTypes.architecture.label',
    description: 'architecture Label',
    defaultMessage: 'Architecture',
  },
  architectureValue: {
    id: 'schemas.licenseTypes.architecture.value',
    description: 'architecture Value',
    defaultMessage: 'architecture',
  },

  otherLabel: {
    id: 'schemas.licenseTypes.other.label',
    description: 'other Label',
    defaultMessage: 'Other',
  },
  otherValue: {
    id: 'schemas.licenseTypes.other.value',
    description: 'other Value',
    defaultMessage: 'other',
  },

  generalContractorsLabel: {
    id: 'schemas.licenseTypes.homeServices.generalContractors.label',
    description: 'generalContractors Label',
    defaultMessage: 'General Contractors',
  },
  generalContractorsValue: {
    id: 'schemas.licenseTypes.homeServices.generalContractors.value',
    description: 'generalContractors Value',
    defaultMessage: 'homeServices',
  },
  plumbersLabel: {
    id: 'schemas.licenseTypes.homeServices.plumbers.label',
    description: 'plumbers Label',
    defaultMessage: 'Plumbers',
  },
  plumbersValue: {
    id: 'schemas.licenseTypes.homeServices.plumbers.value',
    description: 'plumbers Value',
    defaultMessage: 'homeServices',
  },
  electriciansLabel: {
    id: 'schemas.licenseTypes.homeServices.electricians.label',
    description: ' Label',
    defaultMessage: 'Electricians',
  },
  electriciansValue: {
    id: 'schemas.licenseTypes.homeServices.electricians.value',
    description: 'electricians Value',
    defaultMessage: 'homeServices',
  },
  landscapingContractorsLabel: {
    id: 'schemas.licenseTypes.homeServices.landscapingContractors.label',
    description: 'landscapingContractors Label',
    defaultMessage: 'Landscaping Contractors',
  },
  landscapingContractorsValue: {
    id: 'schemas.licenseTypes.homeServices.landscapingContractors.value',
    description: 'landscapingContractors Value',
    defaultMessage: 'homeServices',
  },
});

export const licenseTypeList = [
  'schemas.licenseTypes.professionalBeauty',
  'schemas.licenseTypes.homeServices',
  'schemas.licenseTypes.architecture',
  'schemas.licenseTypes.other',
];

export const homeServiceslicenseTypeList = [
  'schemas.licenseTypes.homeServices.generalContractors',
  'schemas.licenseTypes.homeServices.plumbers',
  'schemas.licenseTypes.homeServices.electricians',
  'schemas.licenseTypes.homeServices.landscapingContractors',
  'schemas.licenseTypes.architecture',
  'schemas.licenseTypes.other',
];

export const licenseTypeValuesList = [
  'professionalBeauty',
  'homeServices',
  'architecture',
  'other',
];
