import { useAppDispatch } from '@hooks/useAppDispatch';
import { useEffect, useState } from 'react';
import { useAppSelector } from '@hooks/useAppSelector';
import {
  selectAuthentication,
  startAuthByEmail,
} from '@features/verification/steps/auth/authSlice';
import { MeshLabel, useWizard } from '@shared/components';
import { FormattedMessage, useIntl } from 'react-intl';
import { Box, TextField, Typography } from '@mui/material';
import * as React from 'react';

const Unauthorized = () => {
  const dispatch = useAppDispatch();
  const [form, setForm] = useState(null);
  const auth = useAppSelector(selectAuthentication);
  const { overrideButtonsController } = useWizard();
  const intl = useIntl();
  const handleSubmit = () => {
    if (!form) {
      return;
    }
    const data = new FormData(form);
    dispatch(startAuthByEmail(data.get('email').toString()));
  };
  const handleKey = (e: React.KeyboardEvent<HTMLDivElement>) => {
    if (e.code === 'Enter') {
      handleSubmit();
    }
  };

  useEffect(() => {
    console.log('override Unauthorized');

    overrideButtonsController({
      next: {
        onClick: handleSubmit,
        isDisabled: () => false,
      },
      previous: {
        isDisabled: () => true,
      },
    });
  }, [form]);

  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
        width: '100%',
        gap: 2,
      }}
      component='form'
      noValidate
      ref={ref => {
        setForm(ref);
      }}
      onSubmit={e => e.preventDefault()}
    >
      <MeshLabel
        label={intl.formatMessage({
          description:
            'Placeholder message for email address field during authentication',
          defaultMessage: 'Email Address',
        })}
      >
        <TextField
          fullWidth
          error={!!auth.errorMessage}
          helperText={
            auth.errorMessage
              ? intl.formatMessage({ id: auth.errorMessage })
              : ''
          }
          onKeyDown={handleKey}
          defaultValue={auth.emailAddress}
          id='email'
          required
          name='email'
          size='small'
          autoComplete='email'
          autoFocus
        />
      </MeshLabel>
      <Typography variant='body2' textAlign='center'>
        <FormattedMessage
          description='Email required disclaimer'
          defaultMessage='This email address will be used by Mesh for important notifications about your business identity and to help you maintain your Verified status.'
        />
      </Typography>
    </Box>
  );
};

export default Unauthorized;
