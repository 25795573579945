import * as React from 'react';
import type { SelectChangeEvent } from '@mui/material';
import { FormControl, FormHelperText, MenuItem, Select } from '@mui/material';
import { useIntl } from 'react-intl';
import type { EndpointInput, EndpointOption } from '@utils/schema';
import { MeshLabel } from '@shared/components';

const VerifyInputText = ({
  input,
  value = '',
  error,
  setFieldValue,
}: {
  input: EndpointInput;
  value: string;
  error: string;
  setFieldValue: (value: string) => void;
}): JSX.Element => {
  const intl = useIntl();
  const handleChange = (event: SelectChangeEvent) => {
    setFieldValue(event.target.value as string);
  };
  const options: EndpointOption[] = React.useMemo(() => {
    if ('options' in input && input.options) {
      return input.options;
    }
    return [];
  }, [input]);
  return (
    <FormControl
      fullWidth
      error={!!error}
      required={input.isRequired}
      size='small'
    >
      <MeshLabel
        required={input.isRequired}
        label={intl.formatMessage({ id: input.inputDescription })}
      >
        <Select
          id={input.inputName}
          value={value}
          label={intl.formatMessage({ id: input.inputDescription })}
          onChange={handleChange}
        >
          {options.map(({ value, label }: EndpointOption) => (
            <MenuItem
              key={`select-${input.inputName}-item-${label}`}
              value={value}
            >
              {intl.formatMessage({ id: label })}
            </MenuItem>
          ))}
        </Select>
      </MeshLabel>
      <FormHelperText>
        {error && intl.formatMessage({ id: error })}
      </FormHelperText>
    </FormControl>
  );
};

export default VerifyInputText;
