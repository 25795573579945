import React from 'react';

import { WizardValues } from '../types/types';
import WizardContext from '../contexts/wizardContext';

const useWizard = () => {
  const context = React.useContext(WizardContext);
  return context as WizardValues;
};

export default useWizard;
