import React, { useContext } from 'react';
import { MeshWizard } from '@shared/components';
import { Box, Typography } from '@mui/material';
import Auth from './steps/auth/Auth';
import queryString from 'query-string';
import {
  selectAuthentication,
  startAuthByUserId,
} from './steps/auth/authSlice';
import { useLocation } from 'react-router-dom';
import { useAppDispatch } from '@hooks/useAppDispatch';
import VerifyForm from './steps/verify-form/VerifyForm';
import { useAppSelector } from '@hooks/useAppSelector';
import { selectEndpoint } from '@features/endpoint/endpointSlice';
import { FormattedMessage, useIntl } from 'react-intl';
import ResultStep from '@features/verification/steps/result/ResultStep';
import ReviewAndSubmit from './steps/review-and-submit/ReviewAndSubmit';
import { AnalyticsContext } from '@context/analyticsContext';
import Welcome from './steps/welcome/Welcome';
import AboutYou from './steps/about-you/AboutYou';

const VerificationStepContainer = ({
  children,
}: {
  children: JSX.Element[];
}) => {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        height: '100%',
        gap: '16px',
        alignItems: 'center',
      }}
    >
      {children}
    </Box>
  );
};

const Verification = (): JSX.Element => {
  const dispatch = useAppDispatch();
  const endpointConfig = useAppSelector(selectEndpoint);
  const vspecs = endpointConfig.vspecs;
  const location = useLocation();
  const intl = useIntl();
  const { firstName, lastName, birthdate } =
    useAppSelector(selectAuthentication);
  const { logEvent, addProperties } = useContext(AnalyticsContext);

  const [termsAccepted, setTermsAccepted] = React.useState(false);

  // Don't display "about you" screen if you've already supplied the necessary information
  const addAboutYou = React.useMemo(() => {
    if (!firstName && !lastName && !birthdate) {
      return true;
    }
    return false;
  }, [firstName, lastName, birthdate]);
  const labels: string[] = React.useMemo(() => {
    const labels: string[] = [];
    labels.push(
      intl.formatMessage({
        description: 'Verification wizard step label authenticate',
        defaultMessage: 'Authenticate',
      })
    );
    for (const vspec of vspecs) {
      if (vspec.vsku.nameId) {
        labels.push(intl.formatMessage({ id: vspec.vsku.nameId }));
      }
    }
    if (addAboutYou) {
      labels.push(
        intl.formatMessage({
          description: 'Verification wizard step label about you',
          defaultMessage: 'Tell us about you',
        })
      );
    }
    return labels;
  }, [vspecs, addAboutYou]);

  const { endpointId, customerId } = React.useMemo(() => {
    const {
      endpointId,
      customer: { customerId },
    } = endpointConfig;
    return { endpointId, customerId };
  }, [endpointConfig]);

  React.useEffect(() => {
    if (location?.search) {
      const params = queryString.parse(location.search);
      const id = typeof params.id === 'string' ? params.id : params.id?.[0];
      if (id) {
        dispatch(startAuthByUserId(id));
      }
    }
  }, [location]);

  React.useEffect(() => {
    addProperties({ experience: 'mesh-professional', endpointId, customerId });
    logEvent({ event: 'page_loaded' });
    if (window.opener) {
      window.opener.postMessage({ event: 'page_loaded', data: {} }, '*');
    }
  }, []);
  if (!termsAccepted) {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center', height: '100%' }}>
        <Welcome acceptTerms={() => setTermsAccepted(true)} />
      </Box>
    );
  }

  return (
    <Box sx={{ display: 'flex', justifyContent: 'center', height: '100%' }}>
      <MeshWizard labels={labels}>
        <VerificationStepContainer>
          <Auth />
          <></>
        </VerificationStepContainer>

        {vspecs.map(vspec => (
          <VerificationStepContainer key={vspec.vspecId}>
            <Typography variant='subtitle1' textAlign='center'>
              <FormattedMessage id={vspec.vsku.descriptionId} />
            </Typography>
            <VerifyForm vspec={vspec} hasNext={true} />
          </VerificationStepContainer>
        ))}

        {addAboutYou && (
          <VerificationStepContainer key={'AboutYou'}>
            <AboutYou />
            <></>
          </VerificationStepContainer>
        )}

        <VerificationStepContainer key={'ReviewAndSubmitStep'}>
          <ReviewAndSubmit vowId={endpointId} customerId={customerId} />
          <></>
        </VerificationStepContainer>

        <VerificationStepContainer>
          <Typography variant='h5'>
            <FormattedMessage
              description='Result step title'
              defaultMessage='Thank you!'
            />
          </Typography>
          <Typography variant='subtitle1'>
            <FormattedMessage
              description='Result step subtitle'
              defaultMessage='We will email your verification results within one business day.'
            />
          </Typography>
          <ResultStep />
        </VerificationStepContainer>
      </MeshWizard>
    </Box>
  );
};

export default Verification;
