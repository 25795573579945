import React from 'react';
import { Box, Tooltip, Typography } from '@mui/material';
import { Info } from '@mui/icons-material';
import { BoxProps } from '@mui/material/Box/Box';

const MeshLabel = ({
  label,
  required,
  tooltip,
  children,
  ...props
}: BoxProps & {
  label: string;
  required?: boolean;
  tooltip?: string;
}) => {
  return (
    <Box
      {...props}
      sx={{ display: 'flex', flexDirection: 'column', width: '100%' }}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          color: 'grey.600',
        }}
      >
        <Typography variant='body1'>
          {label}
          {required ? ' *' : ''}
        </Typography>
        {tooltip && (
          <Tooltip title={tooltip}>
            <Info sx={{ ml: 1, width: 16 }} />
          </Tooltip>
        )}
      </Box>
      {children}
    </Box>
  );
};

export default MeshLabel;
