import Card, { CardProps } from '@mui/material/Card';
import { styled } from '@mui/material/styles';

const MeshCard = styled(Card)<CardProps & { fullWidth?: boolean }>(
  ({ theme, fullWidth }) => ({
    backgroundColor: theme.palette.background.default,
    borderRadius: 4,
    boxShadow: 'none',
    width: fullWidth ? '100%' : '',
  })
);

export default MeshCard;
