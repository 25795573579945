import { styled } from '@mui/material/styles';
import useWizard from '../hooks/useWizard';
import { Box, Button, Typography } from '@mui/material';
import { Mesh } from '@shared/icons';
import * as React from 'react';
import { BoxProps } from '@mui/material/Box/Box';
import { FormattedMessage } from 'react-intl';

const CustomButton = styled('button')(({ theme, disabled }) => ({
  width: 48,
  height: 48,
  display: 'flex',
  borderRadius: '50%',
  justifyContent: 'center',
  alignItems: 'center',
  fontSize: 14,
  fontWeight: 400,
  backgroundColor: disabled
    ? theme.palette.grey[300]
    : theme.palette.primary.main,
  color: 'white',
  border: 'none',
  cursor: disabled ? 'initial' : 'pointer',
}));

const Footer = (props: BoxProps) => {
  const { buttonsController } = useWizard();

  return (
    <Box
      {...props}
      sx={{
        display: 'flex',
        flexDirection: 'column',
        ...(props.sx ?? {}),
      }}
    >
      <Box
        sx={{
          display: buttonsController.next.isHidden() ? 'none' : 'flex',
          flexDirection: 'column',
        }}
      >
        <Button
          variant='contained'
          color='emphasis'
          fullWidth
          size='large'
          onClick={buttonsController.next.onClick}
          disabled={buttonsController.next.isDisabled()}
        >
          <FormattedMessage
            description='Next button label for verification wizard'
            defaultMessage='Next'
          />
        </Button>
      </Box>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'center',
          paddingTop: '0.5rem',
        }}
      >
        <Typography
          sx={{
            pr: 1,
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
          variant='subtitle2'
        >
          <FormattedMessage
            description='Wizard footer verifications powered by'
            defaultMessage='Verifications powered by {logo}'
            values={{
              logo: (
                <Box
                  sx={{
                    height: '1.5rem',
                    marginLeft: '0.5rem',
                    display: 'inline-block',
                  }}
                >
                  <Box sx={{ height: '15px', width: '55px' }}>
                    <Mesh />
                  </Box>
                </Box>
              ),
            }}
          />
        </Typography>
      </Box>
    </Box>
  );
};

export default Footer;
