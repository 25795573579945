import React, { createContext, ReactNode, useState } from 'react';
import queryString from 'query-string';

interface AppContextType {
  isLoading: boolean;
  isEmbedded: boolean;
  setIsLoading: (isLoading: boolean) => void;
}
export const AppContext = createContext<AppContextType>({
  isLoading: false,
  isEmbedded: false,
  setIsLoading: null,
});

const AppProvider = ({ children }: { children: ReactNode }) => {
  const [isLoading, setIsLoading] = useState(false);
  const isEmbedded = React.useMemo(() => {
    if (location?.search) {
      const params = queryString.parse(location.search);
      return params.embedded === 'true';
    }
  }, [location]);

  return (
    <AppContext.Provider value={{ isLoading, setIsLoading, isEmbedded }}>
      {children}
    </AppContext.Provider>
  );
};

export default AppProvider;
