import { useAppDispatch } from '@hooks/useAppDispatch';
import { useAppSelector } from '@hooks/useAppSelector';
import {
  selectAuthentication,
  startLogout,
} from '@features/verification/steps/auth/authSlice';
import { MeshCard, useWizard } from '@shared/components';
import { useEffect } from 'react';
import { Box, CardContent, Link, Typography, Stack } from '@mui/material';
import { EditOutlined, Info } from '@mui/icons-material';
import { FormattedMessage } from 'react-intl';
import * as React from 'react';

const Authorized = () => {
  const dispatch = useAppDispatch();
  const auth = useAppSelector(selectAuthentication);
  const { overrideButtonsController } = useWizard();
  const handleChangeEmail = () => {
    dispatch(startLogout());
  };

  useEffect(() => {
    console.log('override Authorized');
    overrideButtonsController({
      next: {
        isDisabled: () => false,
      },
      previous: {
        onClick: handleChangeEmail,
        isDisabled: () => false,
      },
    });
  }, []);

  return (
    <Stack
      direction='column'
      sx={{ height: '100%' }}
      justifyContent='space-between'
    >
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          flexDirection: 'column',
          gap: 2,
        }}
      >
        <MeshCard
          sx={{
            width: '100%',
            backgroundColor: 'grey.100',
          }}
        >
          <CardContent
            sx={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between',
              '&:last-child': { paddingBottom: 1 },
            }}
          >
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'row',
              }}
            >
              <Info sx={{ mr: 1, color: 'grey.400' }} />
              <Typography variant='body1'>
                <FormattedMessage
                  description='Email disclaimer'
                  defaultMessage='Your email: <b>{emailAddress}</b>'
                  values={{
                    emailAddress: auth.emailAddress,
                    b: val => <b>{val}</b>,
                  }}
                />
              </Typography>
            </Box>
            <Box>
              <Link onClick={handleChangeEmail}>
                <EditOutlined color='primary' />
              </Link>
            </Box>
          </CardContent>
        </MeshCard>
        <Typography variant='body2' textAlign='center'>
          <FormattedMessage
            description='Email disclaimer'
            defaultMessage='This email address will be used by Mesh for important notifications
                              about your business identity and to help you maintain your Verified
                              status.'
          />
        </Typography>
      </Box>
    </Stack>
  );
};

export default Authorized;
