import React, { ReactNode, useContext } from 'react';
import { AppBar, Box, Toolbar } from '@mui/material';
import { OverlayLoader } from '@shared/components';
import { AppContext } from '@context/app.context';
import { useAppSelector } from '@hooks/useAppSelector';
import { selectEndpoint } from '@features/endpoint/endpointSlice';

const HostedView = ({
  customerLogo,
  children,
}: {
  customerLogo: string;
  children: ReactNode;
}) => {
  return (
    <>
      <AppBar
        position='fixed'
        sx={{
          display: {
            xs: 'none',
            sm: 'flex',
          },
          alignItems: 'center',
          backgroundColor: 'white',
          boxShadow: 'none',
        }}
      >
        <Toolbar>
          <img src={customerLogo} height={40} />
        </Toolbar>
      </AppBar>
      <Box
        sx={{
          backgroundColor: 'background.default',
          pt: {
            xs: 0,
            sm: '76px',
          },
          height: '100vh',
          width: '100%',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
          }}
        >
          <Box
            sx={{
              width: {
                xs: '100%',
                sm: '70%',
              },
              height: {
                xs: '100vh',
                sm: '100vh',
              },
              minWidth: '350px',
              maxWidth: '460px',
              maxHeight: {
                xs: '100vh',
                sm: '800px',
              },
            }}
          >
            {children}
          </Box>
        </Box>
      </Box>
    </>
  );
};

const EmbeddedView = ({ children }: { children: ReactNode }) => {
  return (
    <Box
      sx={{
        backgroundColor: 'background.default',
        height: '100%',
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
      }}
    >
      <Box
        sx={{
          height: '100%',
          width: '100%',
          minWidth: '350px',
          maxWidth: '1000px',
          minHeight: '600px',
          maxHeight: '1000px',
        }}
      >
        {children}
      </Box>
    </Box>
  );
};

const CustomerLayout = ({ children }: { children: ReactNode }) => {
  const { isLoading } = useContext(AppContext);
  const endpointConfig = useAppSelector(selectEndpoint);
  const { isEmbedded } = useContext(AppContext);

  if (isEmbedded) {
    return (
      <>
        <EmbeddedView>{children}</EmbeddedView>
        <OverlayLoader isLoading={isLoading} />
      </>
    );
  }
  return (
    <>
      <HostedView customerLogo={endpointConfig.customer.customerLogo}>
        {children}
      </HostedView>
      <OverlayLoader isLoading={isLoading} />
    </>
  );
};

export default CustomerLayout;
