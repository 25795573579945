import { selectEndpoint } from '@features/endpoint/endpointSlice';
import { useAppSelector } from '@hooks/useAppSelector';
import { MeshFeedback, useWizard } from '@shared/components';

import React, { useContext, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { selectAuthentication } from '../auth/authSlice';
import { AppContext } from '@context/app.context';

const ResultStep = () => {
  const endpointConfig = useAppSelector(selectEndpoint);
  const { overrideButtonsController } = useWizard();
  const { isEmbedded } = useContext(AppContext);
  const { customerProfessionalId } = useAppSelector(selectAuthentication);
  const { redirectSettings } = endpointConfig;

  useEffect(() => {
    const nextHandler = isEmbedded
      ? () => {
          window.close();
        }
      : () => {
          const { redirectUri, includeUser } = redirectSettings || {};
          if (redirectUri) {
            const user = includeUser ? `?id=${customerProfessionalId}` : '';
            window.location.href = `${redirectUri}${user}`;
          }
        };

    const hideNext = isEmbedded || redirectSettings ? false : true;
    overrideButtonsController({
      next: {
        isHidden: () => hideNext,
        isDisabled: () => hideNext,
        onClick: nextHandler,
      },
      previous: {
        isHidden: () => true,
      },
    });
  }, [isEmbedded, redirectSettings, customerProfessionalId]);

  useEffect(() => {
    if (window.opener) {
      window.opener.postMessage(
        { event: 'verification_complete', data: { status: 'pending' } },
        '*'
      );
    }
  }, []);

  return <MeshFeedback />;
};

export default ResultStep;
