import React, { useEffect, useState } from 'react';
import { Box, Link, Typography } from '@mui/material';
import { FormattedMessage } from 'react-intl';
import { UsersnapProvider } from '@context/userSnapContext';

const FeedbackLoader = () => {
  const [container, setContainer] = useState(null);
  useEffect(() => {
    console.log('setting container');
    setContainer(document.getElementById('feedbackContainer'));
  }, []);
  console.log('got container', container);
  if (!container) {
    return null;
  }
  return <UsersnapProvider initParams={{ mountNode: container }} />;
};

const MeshFeedback = () => {
  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
      }}
    >
      <div id='feedbackContainer' />
      <FeedbackLoader />
      <Typography sx={{ mt: 2 }} variant='body2'>
        <FormattedMessage
          description='Help text disclaimer'
          defaultMessage='Need help? Contact us at'
        />{' '}
        <Link href='mailto:support@mesh.id'>support@mesh.id</Link>
      </Typography>
    </Box>
  );
};

export default MeshFeedback;
