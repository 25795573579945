import { call, put, select, takeEvery } from 'redux-saga/effects';
import { makeGraphQlCall } from '../../utils/graphql';
import {
  errorCreateVerificationOrder,
  finishCreateVerificationOrder,
  startCreateVerificationOrder,
  startCreateVerificationOrderAction,
} from './verificationOrderSlice';
import { selectAuthentication } from '@features/verification/steps/auth/authSlice';

const createVerificationOrderMutation = `mutation CreateVerificationOrder($verificationOrder: VerificationOrderCreated!) {
  createVerificationOrder(verificationOrder: $verificationOrder) {
    response
  }
}`;

function* handleStartCreateVerificationOrder(
  action: startCreateVerificationOrderAction
) {
  console.log('Starting CreateVerificationOrder');
  try {
    const { accessToken } = selectAuthentication(yield select());
    const data: { createVerificationOrder: string } = yield call(
      makeGraphQlCall,
      {
        query: createVerificationOrderMutation,
        variables: { verificationOrder: action.payload.verificationOrder },
        accessToken: accessToken,
      }
    );
    yield put(finishCreateVerificationOrder(data.createVerificationOrder));
  } catch (e) {
    yield put(errorCreateVerificationOrder(e.message));
  }
}

function* createVerificationOrderSaga() {
  yield takeEvery(
    startCreateVerificationOrder.type,
    handleStartCreateVerificationOrder
  );
}

export default createVerificationOrderSaga;
