import { styled } from '@mui/material/styles';
import {
  Box,
  CircularProgress,
  StepConnector,
  Typography,
} from '@mui/material';
import { Check } from '@mui/icons-material';
import * as React from 'react';
import { useWizard } from '@shared/components';
import { BoxProps } from '@mui/material/Box/Box';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import { useAppSelector } from '@hooks/useAppSelector';
import { selectEndpoint } from '@features/endpoint/endpointSlice';

const StepNumberContainer = styled('div')<{
  completed?: boolean;
  active?: boolean;
}>(({ theme, active, completed }) => ({
  width: 32,
  height: 32,
  display: 'flex',
  borderRadius: '50%',
  border: '1px solid',
  borderColor: theme.palette.grey[300],
  justifyContent: 'center',
  alignItems: 'center',
  fontSize: 14,
  fontWeight: 300,
  ...(active && {
    backgroundColor: theme.palette.grey[300],
  }),
  ...(completed && {
    backgroundColor: theme.palette.primary.main,
  }),
}));

const StepContainer = ({
  step,
  activeStep,
  label,
}: {
  step: number;
  activeStep: number;
  label: string;
}) => {
  return (
    <Box sx={{ display: 'flex', alignItems: 'center', columnGap: 1 }}>
      <StepNumberContainer
        completed={activeStep > step}
        active={activeStep === step}
      >
        {activeStep > step ? (
          <Check sx={{ color: 'white', width: 14 }} />
        ) : (
          <Typography>{step + 1}</Typography>
        )}
      </StepNumberContainer>
      <Typography color='emphasis.dark'>{label}</Typography>
    </Box>
  );
};

class StepHeader extends React.Component<{
  step: number;
  activeStep: number;
  label: string;
  lastStep: boolean;
}> {
  render() {
    return (
      <>
        <StepContainer
          step={this.props.step}
          activeStep={this.props.activeStep}
          label={this.props.label}
        ></StepContainer>
        {!this.props.lastStep && <StepConnector />}
      </>
    );
  }
}

const CustomButton = styled('button')(({ theme, disabled }) => ({
  width: 48,
  height: 48,
  display: 'flex',
  borderRadius: '50%',
  justifyContent: 'center',
  alignItems: 'center',
  fontSize: 14,
  fontWeight: 400,
  color: disabled ? theme.palette.grey[300] : theme.palette.primary.main,
  backgroundColor: 'transparent',
  border: 'none',
  cursor: disabled ? 'initial' : 'pointer',
}));

const Header = ({
  labels,
  ...props
}: BoxProps & { labels?: Array<string> }) => {
  const { effectiveStep: activeStep, buttonsController } = useWizard();
  const endpointConfig = useAppSelector(selectEndpoint);
  const header = labels[activeStep];
  const percentComplete = ((activeStep + 1) / labels.length) * 100;
  const { customerLogo } = endpointConfig.customer;
  // Fix an animation bug related to activeStep being undefined
  if (Number.isNaN(percentComplete)) {
    return null;
  }
  return (
    <>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          flexDirection: 'row',
          paddingBottom: '1rem',
        }}
      >
        {!buttonsController.previous.isHidden() && (
          <CustomButton
            onClick={buttonsController.previous.onClick}
            disabled={buttonsController.previous.isDisabled()}
          >
            <ArrowBackIosIcon sx={{ fontSize: '1.8rem' }} />
          </CustomButton>
        )}
        {buttonsController.previous.isHidden() && <CustomButton disabled />}
        <Box
          sx={{
            flexGrow: 1,
            justifyContent: 'center',
            display: {
              xs: 'flex',
              sm: 'none',
            },
          }}
        >
          <img src={customerLogo} height={32} />
        </Box>

        <CustomButton disabled />
      </Box>

      {header && (
        <Box
          {...props}
          sx={{
            display: 'flex',
            justifyContent: 'flex-start',
            alignItems: 'center',
            columnGap: 1,
            ...(props.sx ?? {}),
          }}
        >
          <Box sx={{ position: 'relative' }}>
            <CircularProgress
              variant='determinate'
              sx={{
                color: 'primary.light',
              }}
              size={48}
              thickness={6}
              value={100}
            />
            <CircularProgress
              variant='determinate'
              value={percentComplete}
              disableShrink
              sx={{
                position: 'absolute',
                left: 0,
              }}
              size={48}
              thickness={6}
            />
          </Box>
          <Typography variant='h3' color='emphasis.dark'>
            {header}
          </Typography>
        </Box>
      )}
    </>
  );
};
export default Header;
