/**
 * Log messages in the console with a corresponding urgency
 */
export const log = {
  error: (message: any, messageInfo: any) => {
    console.error(message, messageInfo);
  },
  warn: (message: any, messageInfo: any) => {
    console.warn(message, messageInfo);
  },
  debug: (message: any, messageInfo: any) => {
    console.debug(message, messageInfo);
  },
};
