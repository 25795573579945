import React, { useState } from 'react';
import {
  StepValue,
  StepValues,
  WizardButtonsController,
  WizardProps,
  WizardValues,
} from './types/types';
import WizardContext from './contexts/wizardContext';
import Footer from '@shared/components/MeshWizard/components/footer';
import Header from '@shared/components/MeshWizard/components/header';
import { Box, Card, CardContent } from '@mui/material';
import { CardProps } from '@mui/material/Card';

// eslint-disable-next-line react/display-name
const MeshWizard = React.memo(
  ({
    children,
    startIndex = 0,
    labels,
    ...props
  }: React.PropsWithChildren<WizardProps> & CardProps<'div'>) => {
    const [stepValues, setStepValues] = useState<StepValues>({});
    const [count, setCount] = useState(0);
    const [stepList, setStepList] = useState<number[]>([]);
    const [activeStep, setActiveStep] = React.useState(startIndex);
    const hasNextStep = React.useRef(true);
    const hasPreviousStep = React.useRef(false);
    const stepCount = React.Children.toArray(children).length;
    hasNextStep.current = activeStep < stepCount - 1;
    hasPreviousStep.current = activeStep > 0;

    const defaultButtonsController: WizardButtonsController = {
      next: {
        onClick: () => {
          goToNextStep.current();
        },
        isDisabled: () => {
          return !hasNextStep.current;
        },
        isHidden: () => false,
      },
      previous: {
        onClick: () => {
          goToPreviousStep.current();
        },
        isDisabled: () => {
          return !hasPreviousStep.current;
        },
        isHidden: () => false,
      },
    };

    const buttonsController = React.useRef(defaultButtonsController);

    const overrideButtonsController = (
      customButtonsController: WizardButtonsController
    ) => {
      setCount(count + 1);
      buttonsController.current = {
        next: {
          ...defaultButtonsController.next,
          ...customButtonsController.next,
        },
        previous: {
          ...defaultButtonsController.previous,
          ...customButtonsController.previous,
        },
      };
    };
    const goToNextStep = React.useRef(() => {
      if (hasNextStep.current) {
        buttonsController.current = defaultButtonsController;
        setActiveStep(activeStep => activeStep + 1);
      }
    });

    const goToPreviousStep = React.useRef(() => {
      if (hasPreviousStep.current) {
        buttonsController.current = defaultButtonsController;
        setActiveStep(activeStep => activeStep - 1);
      }
    });

    const goToStep = React.useRef((stepIndex: number) => {
      if (stepIndex >= 0 && stepIndex < stepCount) {
        buttonsController.current = defaultButtonsController;
        setActiveStep(stepIndex);
      }
    });

    const setStepValue = React.useRef(
      (stepIndex: number, values: StepValue) => {
        const stepData = stepValues;
        stepData[stepIndex] = values;
        setStepValues(stepData);
      }
    );

    const duplicateCurrentStep = React.useMemo(
      () => () => {
        // Splice is a mutable operation, so this is a bit weird
        stepList.splice(activeStep, 0, stepList[activeStep]);
        setStepList(stepList);
      },
      [activeStep, stepList]
    );

    const wizardValue: WizardValues = React.useMemo(
      () => ({
        stepValues,
        activeStepValue: stepValues[activeStep],
        activeStep,
        effectiveStep: stepList[activeStep],
        stepCount,
        isFirstStep: !hasPreviousStep.current,
        isLastStep: !hasNextStep.current,
        goToStep: goToStep.current,
        goToNextStep: goToNextStep.current,
        goToPreviousStep: goToPreviousStep.current,
        overrideButtonsController,
        duplicateCurrentStep,
        buttonsController: buttonsController.current,
        setStepValue: (value: StepValue) =>
          setStepValue.current(activeStep, value),
      }),
      [activeStep, stepCount, count, stepValues]
    );

    const reactChildren = React.useMemo(
      () => React.Children.toArray(children),
      [children]
    );

    React.useEffect(() => {
      setStepList(reactChildren.map((_, index) => index));
    }, [children]);

    return (
      <WizardContext.Provider value={wizardValue}>
        <Card
          sx={{ backgroundColor: 'white', height: '100%', width: '100%' }}
          {...props}
        >
          <CardContent
            sx={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'space-between',
              gap: 2,
              height: '100%',
            }}
          >
            <Header labels={labels} />
            <Box sx={{ height: '100%' }}>
              {reactChildren[stepList[activeStep]]}
            </Box>
            <Footer />
          </CardContent>
        </Card>
      </WizardContext.Provider>
    );
  }
);
export default MeshWizard;
