import { useAppDispatch } from '@hooks/useAppDispatch';
import { useAppSelector } from '@hooks/useAppSelector';
import {
  selectAuthentication,
  startChallenge,
  startLogout,
  startResendChallengeCode,
} from '@features/verification/steps/auth/authSlice';
import { MeshCard, useWizard } from '@shared/components';
import * as React from 'react';
import { useEffect } from 'react';
import { Box, CardContent, Link, Typography } from '@mui/material';
import { EditOutlined, Info } from '@mui/icons-material';
import { FormattedMessage, useIntl } from 'react-intl';
import { MuiOtpInput } from 'mui-one-time-password-input';

const Challenged = () => {
  const dispatch = useAppDispatch();
  const auth = useAppSelector(selectAuthentication);
  const { overrideButtonsController } = useWizard();
  const [otp, setOtp] = React.useState('');
  const intl = useIntl();
  const handleChangeEmail = () => {
    dispatch(startLogout());
  };
  const handleSubmit = () => {
    dispatch(startChallenge(otp));
  };

  useEffect(() => {
    console.log('override challenged');
    overrideButtonsController({
      next: {
        onClick: handleSubmit,
        isDisabled: () => otp?.length < 6,
      },
      previous: {
        onClick: handleChangeEmail,
        isDisabled: () => false,
      },
    });
  }, [otp]);

  const handleChangeOtp = (newValue: string) => {
    setOtp(newValue);
    if (newValue.length >= 6) {
      dispatch(startChallenge(newValue));
    }
  };

  const handleResendOtpCode = () => {
    setOtp('');
    dispatch(startResendChallengeCode());
  };

  return (
    <Box
      component='form'
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
        gap: 2,
      }}
      noValidate
      onSubmit={e => {
        e.preventDefault();
        handleSubmit();
      }}
    >
      <MeshCard
        sx={{
          width: '100%',
          backgroundColor: 'grey.100',
        }}
      >
        <CardContent
          sx={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
          }}
        >
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              mr: 1,
            }}
          >
            <Info sx={{ mr: 1, color: 'grey.400' }} />
            <Typography variant='body1'>
              <FormattedMessage
                description='Email disclaimer'
                defaultMessage='Your email: <b>{emailAddress}</b>'
                values={{
                  emailAddress: auth.emailAddress,
                  b: val => <b>{val}</b>,
                }}
              />
            </Typography>
          </Box>
          <Box>
            <Link onClick={handleChangeEmail}>
              <EditOutlined color='primary' />
            </Link>
          </Box>
        </CardContent>
      </MeshCard>
      <Typography variant='subtitle1'>
        <FormattedMessage
          description='Verification code disclaimer'
          defaultMessage='Please enter the verification code sent to your email address.'
        />
      </Typography>
      <MuiOtpInput
        sx={{ gap: '6px', maxWidth: '280px' }}
        length={6}
        value={otp}
        onChange={handleChangeOtp}
        autoFocus
      />
      {auth.errorMessage && (
        <Typography variant='subtitle2' color='error'>
          {intl.formatMessage({ id: auth.errorMessage })}
        </Typography>
      )}
      <Typography variant='subtitle2'>
        <Link onClick={handleResendOtpCode}>
          <FormattedMessage
            description='Resend otp code'
            defaultMessage='Resend'
          />
        </Link>{' '}
        <FormattedMessage
          description='verification code text'
          defaultMessage='verification code'
        />
      </Typography>
    </Box>
  );
};

export default Challenged;
