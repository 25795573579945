import { configureStore } from '@reduxjs/toolkit';
import createEngine from 'redux-storage-engine-localstorage';
import filter from 'redux-storage-decorator-filter';
import { createLoader, createMiddleware } from 'redux-storage';
import createSagaMiddleware from 'redux-saga';
import rootSaga from './sagas';
import reducer from './reducers';

// create the saga middleware
const sagaMiddleware = createSagaMiddleware();

// create the storage middleware
const engine = createEngine('mesh-professional');

const filteredEngine = filter(engine, null, [
  ['auth', 'authStep'],
  ['auth', 'accessToken'],
  ['createVerificationOrder'],
]);
const storageMiddleware = createMiddleware(filteredEngine);

export const store = configureStore({
  reducer,
  middleware: getDefaultMiddleware =>
    getDefaultMiddleware().concat(storageMiddleware, sagaMiddleware),
});

// Load any previously saved storage
const load = createLoader(engine);
load(store);

// then run the saga
sagaMiddleware.run(rootSaga);

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
