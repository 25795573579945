import React, { useEffect, useMemo, useState } from 'react';
import {
  Box,
  Button,
  Card,
  CardContent,
  Divider,
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
  Stack,
  TextField,
  Typography,
} from '@mui/material';

export const EmbeddedTester = () => {
  const [linkType, setLinkType] = useState<'alias' | 'vowId'>('alias');
  const [target, setTarget] = useState<string>('');
  const [messages, setMessages] = useState<string[]>([]);
  const openWorkflow = useMemo(() => {
    return () => {
      setMessages([]);
      init_mesh_verification({
        target,
        linkType,
        id: '',
        host: window.location.origin,
        onEvent({ event, data }: { event: string; data: any }) {
          console.log('got event', event, data);
          setMessages(messages => [
            ...messages,
            JSON.stringify({ event, data }),
          ]);
        },
      });
    };
  }, [linkType, target, messages]);

  useEffect(() => {
    const script = document.createElement('script');
    script.src = `${window.location.origin}/assets/mesh-verification.js`;
    script.async = true;
    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  return (
    <Box
      sx={{
        display: 'flex',
        height: '100vh',
        width: '100vw',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: 'background.default',
      }}
    >
      <Card>
        <CardContent>
          <Stack direction='column' spacing={4}>
            <FormControl>
              <FormLabel id='linkType-label'>Alias or vowId?</FormLabel>
              <RadioGroup
                row
                aria-labelledby='linkType-label'
                name='linkType'
                value={linkType}
                onChange={e => setLinkType(e.target.value as 'alias' | 'vowId')}
              >
                <FormControlLabel
                  value='alias'
                  control={<Radio />}
                  label='Alias'
                />
                <FormControlLabel
                  value='vowId'
                  control={<Radio />}
                  label='VowId'
                />
              </RadioGroup>
            </FormControl>
            <TextField
              id='target-field'
              label={linkType}
              variant='outlined'
              value={target}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                setTarget(event.target.value);
              }}
            />
            <Button variant='contained' color='primary' onClick={openWorkflow}>
              Open Workflow
            </Button>
            {messages.length > 0 && <Divider />}
            {messages.map((message, idx) => (
              <Box key={`message-${idx}`}>
                <Typography>{message}</Typography>
              </Box>
            ))}
          </Stack>
        </CardContent>
      </Card>
    </Box>
  );
};
