import React, { Component } from 'react';
import { Box, CircularProgress } from '@mui/material';

class OverlayLoader extends Component<{ isLoading: any }> {
  render() {
    const { isLoading } = this.props;
    return (
      <Box
        sx={{
          background: 'rgba(0,0,0,0.5)',
          position: 'fixed',
          top: 0,
          left: 0,
          bottom: 0,
          right: 0,
          display: isLoading ? 'flex' : 'none',
          alignItems: 'center',
          justifyContent: 'center',
          zIndex: 99999999,
        }}
      >
        <CircularProgress size='5rem' />
      </Box>
    );
  }
}

export default OverlayLoader;
