import { boolean, object, string } from 'yup';
import { defineMessage } from 'react-intl';
import {
  homeServiceslicenseTypeList,
  licenseTypeValuesList,
  stateList,
} from './shared';

defineMessage({
  id: 'schemas.professionalLicense.input.isBusiness.label',
  description:
    'Label for the "isBusiness" field within the professionalLicense input schema',
  defaultMessage: 'Is your business name listed on your license?',
});

defineMessage({
  id: 'schemas.professionalLicense.input.firstName.label',
  description:
    'Label for the "firstName" field within the professionalLicense input schema',
  defaultMessage: 'First Name',
});
defineMessage({
  id: 'schemas.professionalLicense.input.lastName.label',
  description:
    'Label for the "lastName" field within the professionalLicense input schema',
  defaultMessage: 'Last Name',
});
defineMessage({
  id: 'schemas.professionalLicense.input.lastName.error.required',
  description:
    '"required" Error message for the "lastName" field within the professionalLicense input schema',
  defaultMessage: 'Last Name is required',
});

defineMessage({
  id: 'schemas.professionalLicense.input.businessName.label',
  description:
    'Label for the "businessName" field within the professionalLicense input schema',
  defaultMessage: 'Business Name',
});
defineMessage({
  id: 'schemas.professionalLicense.input.businessName.error.required',
  description:
    '"required" Error message for the "businessName" field within the professionalLicense input schema',
  defaultMessage: 'Business Name is required',
});

defineMessage({
  id: 'schemas.professionalLicense.input.licenseType.label',
  description:
    'Label for the "licenseType" field within the professionalLicense input schema',
  defaultMessage: 'License Type',
});

defineMessage({
  id: 'schemas.professionalLicense.input.issuingAuthority.label',
  description:
    'Label for the "issuingAuthority" field within the professionalLicense input schema',
  defaultMessage: 'Issuing State',
});
defineMessage({
  id: 'schemas.professionalLicense.input.issuingAuthority.error.required',
  description:
    '"required" Error message for the "issuingAuthority" field within the professionalLicense input schema',
  defaultMessage: 'Issuing State is required',
});

defineMessage({
  id: 'schemas.professionalLicense.input.licenseNumber.label',
  description:
    'Label for the "licenseNumber" field within the professionalLicense input schema',
  defaultMessage: 'License Number',
});
defineMessage({
  id: 'schemas.professionalLicense.input.licenseNumber.error.required',
  description:
    '"required" Error message for the "licenseNumber" field within the professionalLicense input schema',
  defaultMessage: 'License Number is required',
});

export const PROFESSIONAL_LICENSE_INPUT_1_0_0 = object({
  isBusiness: boolean()
    .label('schemas.professionalLicense.input.isBusiness.label')
    .default(false)
    .required(),
  businessName: string()
    .label('schemas.professionalLicense.input.businessName.label')
    .default('')
    .when('isBusiness', {
      is: true,
      then: schema =>
        schema.required(
          'schemas.professionalLicense.input.businessName.error.required'
        ),
      otherwise: schema => schema.meta({ hidden: true }),
    }),
  firstName: string()
    .label('schemas.professionalLicense.input.firstName.label')
    .default(''),
  lastName: string()
    .label('schemas.professionalLicense.input.lastName.label')
    .default('')
    .when('isBusiness', {
      is: false,
      then: schema =>
        schema.required(
          'schemas.professionalLicense.input.lastName.error.required'
        ),
    }),

  licenseType: string()
    .label('schemas.professionalLicense.input.licenseType.label')
    .required('schemas.professionalLicense.input.licenseType.error.required')
    .default('')
    .meta({
      dropdownOptions: homeServiceslicenseTypeList,
    })
    .oneOf(licenseTypeValuesList),

  issuingAuthority: string()
    .label('schemas.professionalLicense.input.issuingAuthority.label')
    .required(
      'schemas.professionalLicense.input.issuingAuthority.error.required'
    )
    .default('')
    .meta({
      dropdownOptions: stateList,
    }),
  licenseNumber: string()
    .label('schemas.professionalLicense.input.licenseNumber.label')
    .required('schemas.professionalLicense.input.licenseNumber.error.required')
    .default(''),
});
