import { call, put, takeEvery } from 'redux-saga/effects';
import { PayloadAction } from '@reduxjs/toolkit';
import { makeGraphQlCall } from '../../utils/graphql';
import {
  EndpointConfig,
  errorGetEndpoint,
  finishGetEndpoint,
  startGetEndpoint,
  startGetEndpointByAlias,
} from './endpointSlice';

const endpointQueryResponse = `
  customer {
    customerId
    customerLogo
    customerName
  }
  endpointId
  redirectSettings {
    includeUser
    redirectUri
  }
  vspecs {
    vsku {
      vsku
      nameId
      descriptionId
      titleId
      jurisdiction
      inputClaims {
        nameId
        errorId
        isRequired
        fieldName
        label
        isPrimary
        isDerived
        dataType
        options {
          values {
            value
            label
            labelStringId
          }
        }
      }
      outputResults {
        nameId
        errorId
        isRequired
        fieldName
        label
        isPrimary
        isDerived
        dataType
        options {
          values {
            value
            label
          }
        }
      }
      requiredInputs
      primaryInputs
      inputCategories
      requiredOutputs
      allowMultiples
    }
    vspecId
  }
`;

const getEndpointByAliasQuery = `query GetEndpointByAlias($alias: String!) {
  getEndpointByAlias(alias: $alias) {
    ${endpointQueryResponse}
  }
}`;

const getEndpointQuery = `query GetEndpoint($endpointId: String!) {
  getEndpoint(endpointId: $endpointId) {
    ${endpointQueryResponse}
  }
}`;

// Leaving this commented out for testing the new document screen until we have the VSku defined.
// const DOCUMENT_VSPEC = {
//   vsku: {
//     vsku: 'v-proof-of-business@1.0',
//     nameId: 'v-proof-of-business@1.0-name',
//     titleId: 'v-proof-of-business@1.0-title',
//     descriptionId: 'v-proof-of-business@1.0-description',
//     allowMultiples: false,
//     jurisdiction: 'US', // This value is likely a hard-coded value rather than linked to a claim. We should figure out how to handle that.
//     type: VSkuType.document, // The valid values are 'field' and 'document'
//   } as DocumentVSku,
//   vspecId: 'documentUUID',
//   type: VSkuType.document,
// };

function* handleStartGetEndpoint(action: PayloadAction<string>) {
  console.log('Starting getEndpoint');
  try {
    const data: { getEndpoint: EndpointConfig } = yield call(makeGraphQlCall, {
      query: getEndpointQuery,
      variables: { endpointId: action.payload },
    });
    yield put(finishGetEndpoint(data.getEndpoint));
  } catch (e) {
    console.log('got error', e);
    yield put(errorGetEndpoint(e.message));
  }
}

function* handleStartGetEndpointByAlias(action: PayloadAction<string>) {
  console.log('Starting getEndpoint with alias');
  try {
    const data: { getEndpointByAlias: EndpointConfig } = yield call(
      makeGraphQlCall,
      {
        query: getEndpointByAliasQuery,
        variables: { alias: action.payload },
      }
    );
    yield put(finishGetEndpoint(data.getEndpointByAlias));
  } catch (e) {
    console.log('got error', e);
    yield put(errorGetEndpoint(e.message));
  }
}

function* endpointSaga() {
  yield takeEvery(startGetEndpoint.type, handleStartGetEndpoint);
  yield takeEvery(startGetEndpointByAlias.type, handleStartGetEndpointByAlias);
}

export default endpointSaga;
