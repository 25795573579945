import * as React from 'react';
import { useContext, useEffect } from 'react';
import { CircularProgress, Typography } from '@mui/material';
import { FormattedMessage } from 'react-intl';

import { AuthorizationSteps, selectAuthentication } from './authSlice';
import { useAppSelector } from '@hooks/useAppSelector';
import Unauthorized from './steps/Unauthorized';
import Challenged from '@features/verification/steps/auth/steps/Challenged';
import Authorized from '@features/verification/steps/auth/steps/Authorized';
import { AnalyticsContext } from '@context/analyticsContext';
import { ExperimentContext } from '@context/experimentContext';

const Auth = (): JSX.Element => {
  const auth = useAppSelector(selectAuthentication);
  const { logEvent, identifyUser } = useContext(AnalyticsContext);
  const { targetUser } = useContext(ExperimentContext);

  useEffect(() => {
    if (auth.authStep === AuthorizationSteps.Unauthorized) {
      logEvent({ event: 'enter_email' });
    }

    if (auth.authStep === AuthorizationSteps.Challenged) {
      logEvent({ event: 'one_time_password' });
    }

    if (auth.authStep === AuthorizationSteps.Authorized) {
      identifyUser({ userId: auth.emailAddress });
      targetUser({ userId: auth.emailAddress });
      logEvent({ event: 'authorized' });
    }
  }, [auth.authStep]);

  if (auth.isProcessing) {
    return <CircularProgress />;
  }
  if (auth.authStep === AuthorizationSteps.Unauthorized) {
    return <Unauthorized />;
  }

  if (auth.authStep === AuthorizationSteps.Challenged) {
    return <Challenged />;
  }

  if (auth.authStep === AuthorizationSteps.Authorized) {
    return <Authorized />;
  }

  return (
    <Typography>
      <FormattedMessage
        description='Generic error for authentication flow'
        defaultMessage='There was an error while logging in. Please contact support at
        support@mesh.id'
      />
    </Typography>
  );
};

export default Auth;
