import React, { useEffect, useMemo } from 'react';
import VerifyInput from '@shared/components/VerifyInput/VerifyInput';
import { Box, Stack, Typography, Link, CircularProgress } from '@mui/material';
import { FormattedMessage, defineMessage } from 'react-intl';
import { EndpointType } from '@utils/schema';
import { useWizard } from '@shared/components/MeshWizard';
import { selectAuthentication, startAttributeUpdate } from '../auth/authSlice';
import { useAppDispatch } from '@hooks/useAppDispatch';
import { useAppSelector } from '@hooks/useAppSelector';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
dayjs.extend(customParseFormat);

defineMessage({
  id: 'schemas.aboutYou.input.firstName.label',
  description: 'Tell us about you first name input label',
  defaultMessage: 'First Name',
});

defineMessage({
  id: 'schemas.aboutYou.input.lastName.label',
  description: 'Tell us about you last name input label',
  defaultMessage: 'Last Name',
});

defineMessage({
  id: 'schemas.aboutYou.input.birthdate.label',
  description: 'Tell us about you birthdate input label',
  defaultMessage: 'Date of Birth',
});

const schema = [
  {
    inputName: 'firstName',
    inputDescription: 'schemas.aboutYou.input.firstName.label',
    inputType: EndpointType.TextInput,
    isRequired: true,
  },
  {
    inputName: 'lastName',
    inputDescription: 'schemas.aboutYou.input.lastName.label',
    inputType: EndpointType.TextInput,
    isRequired: true,
  },
  {
    inputName: 'birthdate',
    inputDescription: 'schemas.aboutYou.input.birthdate.label',
    inputType: EndpointType.DateInput,
    isRequired: true,
  },
];

export const AboutYou = () => {
  const { firstName, lastName, birthdate, isProcessing } =
    useAppSelector(selectAuthentication);
  const [formData, setFormData] = React.useState<Record<string, any>>({
    firstName,
    lastName,
    birthdate: dayjs(birthdate, 'MM/DD/YYYY'),
  });
  const { overrideButtonsController, goToNextStep } = useWizard();
  const [saving, setSaving] = React.useState(false);

  const dispatch = useAppDispatch();
  const handleSubmit = useMemo(
    () => () => {
      dispatch(
        startAttributeUpdate({
          ...formData,
          birthdate: formData.birthdate.format('MM/DD/YYYY'),
        })
      );
      setSaving(true);
    },
    [formData]
  );
  useEffect(() => {
    overrideButtonsController({
      next: {
        onClick: handleSubmit,
        isDisabled: () => false,
      },
      previous: {},
    });
  }, [handleSubmit]);

  useEffect(() => {
    if (saving && !isProcessing) {
      setSaving(false);
      goToNextStep();
    }
  }, [saving, isProcessing]);

  const setFieldValue = useMemo(() => {
    return (fieldName: string) => (value: any) => {
      setFormData({ ...formData, [fieldName]: value });
    };
  }, [formData]);

  if (saving) {
    return (
      <Box
        sx={{
          width: '100%',
          height: '100%',
          justifyContent: 'center',
          alignItems: 'center',
          display: 'flex',
        }}
      >
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        alignItems: 'center',
        width: '100%',
        height: '100%',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'flex-start',
          alignItems: 'center',
          width: '100%',
        }}
      >
        <Typography variant='body1' textAlign='center'>
          <FormattedMessage
            description='Tell us about you top body message'
            defaultMessage='Enter your name as it appears on your government ID.'
          />
        </Typography>
        <Stack spacing={1.5} sx={{ width: '100%' }}>
          {schema.map(input => (
            <VerifyInput
              key={`field-${input.inputName}`}
              input={input}
              value={formData[input.inputName]}
              setFieldValue={setFieldValue(input.inputName)}
              error=''
            />
          ))}
        </Stack>
        <Typography variant='caption' color='grey.600'>
          <FormattedMessage
            description='Tell us about you birthdate input helper text'
            defaultMessage='This info helps your verification, and we will keep it private. Learn more about our <privacyPrinciples>Privacy Principles</privacyPrinciples>'
            values={{
              privacyPrinciples: val => (
                <Link
                  target='_blank'
                  href='https://www.mesh.id/mesh-privacy-principles'
                >
                  {val}
                </Link>
              ),
            }}
          />
        </Typography>
      </Box>
      <Typography variant='caption' textAlign='center' color='grey.600'>
        <FormattedMessage
          description='Tell us about you certification footer message'
          defaultMessage='By continuing, I certify that I am authorized to act on behalf of the business/licensee provided to Mesh.'
        />
      </Typography>
    </Box>
  );
};

export default AboutYou;
