import React from 'react';
import { Box, Typography } from '@mui/material';
import { Mesh } from '@shared/icons';

const Error = () => (
  <Box
    sx={{
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      flexDirection: 'column',
      minHeight: '100vh',
      color: 'primary.main',
    }}
  >
    <Box sx={{ height: '15px', width: '55px' }}>
      <Mesh />
    </Box>
    <Typography variant='h1'>404</Typography>
    <Typography variant='h5'>Page not found</Typography>
  </Box>
);
export default Error;
