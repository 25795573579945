import { SplitExperiment, SplitExperimentContext } from './split';

export interface ExperimentContextType {
  initialized: boolean;
  getExperiment: (experimentName: string) => string;
  targetUser: ({ userId }: { userId: string }) => void;
}

export const Experiment = SplitExperiment;
export const ExperimentContext = SplitExperimentContext;

export const CURRENT_EXPERIMENTS = {
  NewStackMobileFriendlyUI: 'NewStackMobileFriendlyUI',
};
