import { call, put, select, takeEvery } from 'redux-saga/effects';
import { PayloadAction } from '@reduxjs/toolkit';
import { defineMessage } from 'react-intl';
import {
  AttributeUpdates,
  ChallengeRequired,
  UserAttributes,
  finishAttributeUpdate,
  finishAuth,
  finishLogout,
  finishResendChallengeCode,
  requireChallenge,
  selectAuthentication,
  setErrorMessage,
  startAttributeUpdate,
  startAuthByEmail,
  startAuthByUserId,
  startChallenge,
  startLogout,
  startResendChallengeCode,
} from './authSlice';
import {
  AuthenticationDetails,
  CognitoUser,
  CognitoUserAttribute,
  CognitoUserPool,
} from 'amazon-cognito-identity-js';
import config from '@utils/config';
import { makeGraphQlCall } from '@utils/graphql';
import { selectEndpoint } from '../../../endpoint/endpointSlice';
import { log } from '@utils/logger';

const userPool = new CognitoUserPool(config.cognitoConfig);

const lookupIdentifierQuery = `query LookupIdentifier($identifier: ID!) {
  lookupIdentifier(identifier: $identifier) {
    customerProfessionalId
    professional {
      primaryEmailAddress
      professionalId
    }
  }
}`;

const createIdentifierMutation = `mutation CreateIdentifier($customerId: ID!, $emailAddress: String!) {
  createIdentifier(customerId: $customerId, emailAddress: $emailAddress) {
    customerProfessionalId
  }
}`;

defineMessage({
  id: 'authentication.error.genericError',
  description: 'Generic error from the authentication flow',
  defaultMessage:
    'There was an error during authentication. Please try again, or contact support@mesh.id for assistance.',
});

defineMessage({
  id: 'authentication.error.notLoggedIn',
  description: 'Error with not being correctly logged in',
  defaultMessage:
    'There was an error retrieving the logged in user. Please try again, or contact support@mesh.id for assistance.',
});
defineMessage({
  id: 'authentication.error.invalidEmail',
  description: 'Invalid email error from the authentication flow',
  defaultMessage: 'Invalid email.',
});
defineMessage({
  id: 'authentication.error.invalidCode',
  description: 'Invalid code error from the authentication flow',
  defaultMessage: 'Invalid code.',
});
defineMessage({
  id: 'authentication.error.logoutError',
  description: 'Logout error from the authentication flow',
  defaultMessage: 'Error during logout.',
});

let user: CognitoUser;
const createUser = (username: string) => {
  const userData = {
    Username: username,
    Pool: userPool,
  };
  const cognitoUser = new CognitoUser(userData);
  cognitoUser.setAuthenticationFlowType('CUSTOM_AUTH');
  user = cognitoUser;
  return user;
};
const getUser = async () => {
  if (user != null) {
    return user;
  }
  // If not in local variable, try to fetch from the userPool
  const cognitoUser = userPool.getCurrentUser();
  if (cognitoUser != null) {
    return cognitoUser;
  }
  throw new Error('No user was found!');
};

const createIdentifier = async ({
  emailAddress,
  customerId,
}: {
  emailAddress: string;
  customerId: string;
}) => {
  const data = await makeGraphQlCall({
    query: createIdentifierMutation,
    variables: {
      customerId,
      emailAddress,
    },
  });
  console.log('got response', data);
  return data.createIdentifier.customerProfessionalId;
};

const lookupIdentifier = async ({ identifier }: { identifier: string }) => {
  const data = await makeGraphQlCall({
    query: lookupIdentifierQuery,
    variables: {
      identifier,
    },
  });
  console.log('got response', data);
  return data.lookupIdentifier?.professional?.primaryEmailAddress;
};

const performLogin = async ({
  username,
  customerProfessionalId,
  cognitoUser,
}: {
  username: string;
  cognitoUser: CognitoUser;
  customerProfessionalId: string;
}) => {
  const authenticationData = {
    Username: username,
    AuthParameters: {
      CHALLENGE_NAME: 'CUSTOM_CHALLENGE',
      USERNAME: username,
    },
  };
  const authenticationDetails = new AuthenticationDetails(authenticationData);
  return new Promise((resolve, reject) => {
    cognitoUser.initiateAuth(authenticationDetails, {
      onSuccess: async result => {
        console.log('success on auth', result);
        const accessToken = result.getAccessToken().getJwtToken();
        console.log('got accessToken', accessToken);
        const attrs = await getUserAttributes({
          cognitoUser,
        });
        const emailAddress: string = attrs['email'];
        const phoneNumber: string = attrs['phone_number'];
        const firstName: string = attrs['given_name'];
        const lastName: string = attrs['family_name'];
        const birthdate: string = attrs['birthdate'];

        resolve(
          finishAuth({
            emailAddress,
            phoneNumber,
            firstName,
            lastName,
            birthdate,
            accessToken,
            customerProfessionalId,
          })
        );
      },

      customChallenge: challengeParameters => {
        console.log('got challenge', challengeParameters);
        // User authentication depends on challenge response
        resolve(
          requireChallenge({ emailAddress: username, customerProfessionalId })
        );
      },
      onFailure: err => {
        console.log('error on auth', err);
        // alert(err.message || JSON.stringify(err));
        reject(err);
      },
    });
  });
};

const setAttributes = async ({
  cognitoUser,
  attributes,
}: {
  cognitoUser: CognitoUser;
  attributes: Record<string, string>;
}): Promise<void> => {
  const cognitoAttributes = Object.entries(attributes).map(([Name, Value]) => {
    return { Name, Value } as CognitoUserAttribute;
  });
  return new Promise((fulfill, reject) => {
    cognitoUser.updateAttributes(cognitoAttributes, (err, result, details) => {
      if (err) {
        console.log('got err', err);
        reject(err);
        return;
      }
      console.log('got result', result);
      fulfill();
    });
  });
};

const resendChallengeCode = async ({
  cognitoUser,
}: {
  cognitoUser: CognitoUser;
}) => {
  return new Promise((resolve, reject) => {
    cognitoUser.resendConfirmationCode(err => {
      if (err) {
        reject(err);
      } else {
        resolve(startChallenge());
      }
    });
  });
};

const tryChallengeCode = async ({
  challengeCode,
  cognitoUser,
  customerProfessionalId,
}: {
  challengeCode: string;
  cognitoUser: CognitoUser;
  customerProfessionalId: string;
}) => {
  return new Promise((resolve, reject) => {
    cognitoUser.sendCustomChallengeAnswer(challengeCode, {
      onSuccess: async result => {
        console.log('success on auth', result);
        const accessToken = result.getAccessToken().getJwtToken();
        console.log('got accessToken', accessToken);

        const attrs = await getUserAttributes({
          cognitoUser,
        });

        const emailAddress: string = attrs['email'];
        const phoneNumber: string = attrs['phone_number'];
        const firstName: string = attrs['given_name'];
        const lastName: string = attrs['family_name'];
        const birthdate: string = attrs['birthdate'];

        resolve(
          finishAuth({
            emailAddress,
            phoneNumber,
            firstName,
            lastName,
            birthdate,
            accessToken,
            customerProfessionalId,
          })
        );
      },
      customChallenge: challengeParameters => {
        console.log('got challenge', challengeParameters);
        // User authentication depends on challenge response
        reject(new Error('Invalid code. Please try again'));
      },
      onFailure: err => {
        console.log('error on auth', err);
        reject(err);
      },
    });
  });
};

const performLogout = async ({ cognitoUser }: { cognitoUser: CognitoUser }) => {
  return new Promise((resolve, reject) => {
    try {
      cognitoUser.signOut(() => {
        resolve('Done');
      });
    } catch (e) {
      console.log('Error while signing out', e);
      reject(e);
    }
  });
};

const triggerPhoneNumberVerification = async ({
  cognitoUser,
}: {
  cognitoUser: CognitoUser;
}): Promise<void> => {
  return new Promise((fulfill, reject) => {
    console.log('triggering phone verification');
    cognitoUser.getAttributeVerificationCode('phone_number', {
      onSuccess: (success: string) => {
        console.log('success', success);
        fulfill();
      },
      onFailure: (err: Error) => {
        console.log('got err', err);
        reject(err);
      },
    });

    // cognitoUser.verifyAttribute()
  });
};

const verifyPhoneNumber = async ({
  cognitoUser,
  confirmationCode,
}: {
  cognitoUser: CognitoUser;
  confirmationCode: string;
}): Promise<void> => {
  return new Promise((fulfill, reject) => {
    console.log('verifying phone_number with code');
    cognitoUser.verifyAttribute('phone_number', confirmationCode, {
      onSuccess: (success: string) => {
        console.log('success', success);
        fulfill();
      },
      onFailure: (err: Error) => {
        console.log('got err', err);
        reject(err);
      },
    });
  });
};

const getUserAttributes = async ({
  cognitoUser,
}: {
  cognitoUser: CognitoUser;
}): Promise<UserAttributes> => {
  return new Promise((resolve, reject) => {
    try {
      cognitoUser.getUserAttributes((e, attrs) => {
        if (e) {
          console.log('Error getting attributes');
          reject(e);
        }
        console.log('got attr', attrs);
        const mapped = Object.fromEntries(
          attrs.map(({ Name, Value }) => [Name, Value])
        ) as {
          email: string;
          phone_number?: string;
          given_name?: string;
          family_name?: string;
          birthdate?: string;
        };
        resolve(mapped);
      });
    } catch (e) {
      console.log('Error getting attributes');
      throw e;
    }
  });
};

function* handleStartAuthByEmail(action: PayloadAction<string>) {
  try {
    log.debug('got action', action);
    const endpointConfig = selectEndpoint(yield select());
    log.debug('got endpointConfig', endpointConfig);

    const identifier: string = yield call(createIdentifier, {
      emailAddress: action.payload,
      customerId: endpointConfig.customer.customerId,
    });

    const cognitoUser = createUser(action.payload);
    const outputAction: PayloadAction = yield call(performLogin, {
      username: action.payload,
      cognitoUser,
      customerProfessionalId: identifier,
    });
    log.debug('got action', { outputAction, identifier });
    yield put(outputAction);
  } catch (e) {
    log.debug('got error', e);
    yield put(setErrorMessage('authentication.error.genericError'));
  }
}

function* handleStartAuthByUserId(action: PayloadAction<string>) {
  try {
    console.log('got action', action);
    console.log('looking');
    const { payload: identifier } = action;
    // First, we call "lookupIdentifier" to obtain the email address
    const emailAddress: string = yield call(lookupIdentifier, {
      identifier,
    });
    console.log('got email', emailAddress);
    const cognitoUser = createUser(emailAddress);
    const outputAction: PayloadAction = yield call(performLogin, {
      username: emailAddress,
      cognitoUser,
      customerProfessionalId: identifier,
    });
    console.log('got action', outputAction, emailAddress);
    yield put(outputAction);
  } catch (e) {
    console.log('got error', e);
    yield put(setErrorMessage('authentication.error.invalidEmail'));
  }
}

function* handleStartChallenge(action: PayloadAction<string>) {
  try {
    console.log('got action', action);
    const cognitoUser: CognitoUser = yield call(getUser);

    const { customerProfessionalId } = selectAuthentication(yield select());
    const outputAction: PayloadAction = yield call(tryChallengeCode, {
      challengeCode: action.payload,
      cognitoUser,
      customerProfessionalId,
    });
    console.log('got action', outputAction);
    yield put(outputAction);
  } catch (e) {
    console.log('got error', e);
    yield put(setErrorMessage('authentication.error.invalidCode'));
  }
}
function* handleStartLogout() {
  console.log('Starting logout');
  try {
    const cognitoUser: CognitoUser = yield call(getUser);
    if (cognitoUser) {
      yield call(performLogout, { cognitoUser });
    }
    yield put(finishLogout());
  } catch (e) {
    console.log('got error', e);
    yield put(setErrorMessage('authentication.error.logoutError'));
  }
}

function* handleResendChallengeCode() {
  console.log('Resending challenge code');
  try {
    const cognitoUser: CognitoUser = yield call(getUser);
    if (cognitoUser) {
      yield call(resendChallengeCode, { cognitoUser });
    }
    yield put(finishResendChallengeCode());
  } catch (e) {
    console.log('got error', e);
    yield put(setErrorMessage('authentication.error.genericError'));
  }
}

function* handleStartAttributeUpdate(action: PayloadAction<AttributeUpdates>) {
  try {
    const cognitoUser: CognitoUser = yield call(getUser);
    if (!cognitoUser) {
      yield put(setErrorMessage('authentication.error.genericError'));
    }
    const attributes: Record<string, string> = {};
    if (action.payload.phoneNumber) {
      attributes['phone_number'] = action.payload.phoneNumber;
    }
    if (action.payload.firstName) {
      attributes['given_name'] = action.payload.firstName;
    }
    if (action.payload.lastName) {
      attributes['family_name'] = action.payload.lastName;
    }
    if (action.payload.birthdate) {
      attributes['birthdate'] = action.payload.birthdate;
    }
    yield call(setAttributes, { cognitoUser, attributes });
    yield put(finishAttributeUpdate(action.payload));
  } catch (e) {
    console.log('got error', e);
    yield put(setErrorMessage('authentication.error.genericError'));
  }
}

function* authSaga() {
  yield takeEvery(startLogout.type, handleStartLogout);
  yield takeEvery(startAuthByEmail.type, handleStartAuthByEmail);
  yield takeEvery(startAuthByUserId.type, handleStartAuthByUserId);
  yield takeEvery(startChallenge.type, handleStartChallenge);
  yield takeEvery(startResendChallengeCode.type, handleResendChallengeCode);
  yield takeEvery(startAttributeUpdate.type, handleStartAttributeUpdate);
}

export default authSaga;
