import * as React from 'react';
import { TextField } from '@mui/material';
import { useIntl } from 'react-intl';

import type { EndpointInput } from '@utils/schema';
import { MeshLabel } from '@shared/components';

const VerifyInputText = ({
  input,
  value = '',
  error,
  setFieldValue,
}: {
  input: EndpointInput;
  value: string;
  error: string;
  setFieldValue: (value: string) => void;
}): JSX.Element => {
  const intl = useIntl();
  return (
    <MeshLabel
      label={intl.formatMessage({ id: input.inputDescription })}
      required={input.isRequired}
    >
      <TextField
        fullWidth
        size='small'
        error={!!error}
        value={value}
        helperText={error && intl.formatMessage({ id: error })}
        id={input.inputName}
        required={input.isRequired}
        variant='outlined'
        onChange={evt => setFieldValue(evt.target.value)}
        name={input.inputName}
      />
    </MeshLabel>
  );
};

export default VerifyInputText;
