import {
  Box,
  Grid,
  Button,
  TextField,
  Typography,
  CircularProgress,
  Fade,
} from '@mui/material';
import * as React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useWizard } from '@shared/components';
import { useEffect } from 'react';
import {
  VerificationOrderCreated,
  VerificationOrderItem,
} from '@features/verification-order/types';
import { startCreateVerificationOrder } from '@features/verification-order/verificationOrderSlice';
import { useAppDispatch } from '@hooks/useAppDispatch';
import { AnalyticsContext } from '@context/analyticsContext';
import { useAppSelector } from '@hooks/useAppSelector';
import { VSkuType, selectEndpoint } from '@features/endpoint/endpointSlice';
import { selectVerificationOrder } from '@features/verification-order/verificationOrderSlice';

const LABELS = [
  {
    label: (
      <FormattedMessage
        description='First label during progress spinner'
        defaultMessage='Helping you succeed... 🚀'
      />
    ),
    timing: 2000,
  },
  {
    timing: 500,
  },
  {
    label: (
      <FormattedMessage
        description='Second label during submission progress spinner'
        defaultMessage='...and win the day 😁'
      />
    ),
  },
];

const LabelTransitions = ({
  labels,
}: {
  labels: { label?: JSX.Element; timing?: number }[];
}): JSX.Element => {
  const [step, setStep] = React.useState(0);

  useEffect(() => {
    if (labels[step] && labels[step].timing) {
      setTimeout(() => {
        console.log('setting step', step + 1);
        setStep(step + 1);
      }, labels[step].timing);
    }
  }, [step, labels]);

  return (
    <>
      {labels.map(({ label }, index) => (
        <Fade
          key={`transition-${index}`}
          in={step === index}
          appear={index !== 0}
          unmountOnExit
        >
          <Typography variant='body1'>{label}</Typography>
        </Fade>
      ))}
    </>
  );
};

const ReviewAndSubmit = ({
  vowId,
  customerId,
}: {
  vowId: string;
  customerId: string;
}): JSX.Element => {
  const dispatch = useAppDispatch();
  const { goToNextStep, stepValues, overrideButtonsController } = useWizard();
  const { logEvent } = React.useContext(AnalyticsContext);
  const intl = useIntl();
  const endpointConfig = useAppSelector(selectEndpoint);
  const orderConfig = useAppSelector(selectVerificationOrder);
  const [loading, setLoading] = React.useState(false); // TODO: Temporary!!

  const { isLoading, verificationOrderCreated, errorMessage } = orderConfig;
  const vspecs = Object.fromEntries(
    endpointConfig.vspecs.map(({ vspecId, vsku }) => [vspecId, vsku])
  );
  logEvent({ event: 'verification_completed' });
  useEffect(() => {
    overrideButtonsController({
      next: {
        isHidden: () => true,
      },
      previous: {
        isHidden: () => loading,
      },
    });
  }, [loading]);

  const submitVerificationOrder = () => {
    // TODO: Add submitting for additional VskuType values
    const stepData = Object.values(stepValues)
      .filter(({ stepType }) => stepType === VSkuType.field)
      .map(({ inputValues, vspecId, vsku, jurisdiction }) => ({
        inputValues,
        vspecId,
        vsku,
        jurisdiction,
      }));
    const verificationOrderItems = stepData.map(
      ({ inputValues, vspecId, vsku, jurisdiction }, index) => {
        const claims = Object.entries(inputValues).map(([name, value]) => ({
          name,
          value,
        }));
        const jurisdictionClaim = claims.find(
          ({ name }) => name === jurisdiction
        );
        const verificationOrderItem: VerificationOrderItem = {
          itemIndex: index,
          vspecId,
          vsku,
          claims,
          jurisdiction: jurisdictionClaim.value,
        };
        return verificationOrderItem;
      }
    );
    const verificationOrder: VerificationOrderCreated = {
      verifierId: customerId,
      vowId,
      items: verificationOrderItems,
    };
    dispatch(
      startCreateVerificationOrder({
        verificationOrder,
      })
    );
  };

  const handleSubmitButton = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    submitVerificationOrder();
  };

  const allInputClaims = React.useMemo(() => {
    const inputs: Record<string, string> = {};
    if (!stepValues) {
      return [];
    }
    for (const value of Object.values(stepValues)) {
      const { inputValues, primaryInputs, vspecId, jurisdiction } = value;
      const vskuName = intl.formatMessage({ id: vspecs[vspecId].nameId });
      const primaryValues = primaryInputs
        .map(fieldName => inputValues[fieldName])
        .join(' ');

      const outputValue = `${primaryValues} (${inputValues[jurisdiction]})`;
      if (inputs[vspecId]) {
        inputs[vspecId] = [inputs[vspecId], outputValue].join(', ');
      } else {
        inputs[vspecId] = `${vskuName}: ${outputValue}`;
      }
    }

    return Object.entries(inputs);
  }, [stepValues]);

  useEffect(() => {
    if (errorMessage) {
      setLoading(isLoading);
    } else {
      setLoading(true);
    }
  }, [isLoading, errorMessage]);

  useEffect(() => {
    submitVerificationOrder();
  }, []);

  React.useEffect(() => {
    if (verificationOrderCreated) {
      goToNextStep();
    }
  }, [verificationOrderCreated]);

  if (loading) {
    return (
      <Box
        sx={{
          flexGrow: 1,
          mt: 8,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <CircularProgress size='4rem' />
        <Box sx={{ mt: 6, width: '100%' }}>
          <LabelTransitions labels={LABELS} />
        </Box>
      </Box>
    );
  }

  return (
    <Box
      sx={{
        flexGrow: 1,
        my: 2,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
      }}
      component='form'
      onSubmit={evt => {
        console.log('got submit');
        handleSubmitButton(evt);
      }}
      noValidate
    >
      {errorMessage && (
        <Typography variant='body2' textAlign='center' sx={{ color: 'red' }}>
          <FormattedMessage
            description='Error message for submitting form'
            defaultMessage='There was an error submitting your order. Please try again, or contact support@mesh.id for assistance.'
          />
        </Typography>
      )}
      <Grid container sx={{ mx: 'auto' }}>
        {allInputClaims.map(([fieldName, fieldValue]) => (
          <Grid item xs={12} key={`field_${fieldName}`} sx={{ my: 2, mx: 1 }}>
            <TextField
              fullWidth
              size='small'
              disabled
              id={`field_${fieldName}`}
              variant='outlined'
              value={fieldValue}
            />
          </Grid>
        ))}
        <Grid item xs={12} key={`submitButton`} sx={{ my: 2, mx: 0.8 }}>
          <Button
            type='submit'
            fullWidth
            variant='contained'
            sx={{ py: 1, px: 0 }}
          >
            <FormattedMessage
              description='Submit button label for verification submission form'
              defaultMessage='Submit Verification'
            />
          </Button>
        </Grid>
      </Grid>
    </Box>
  );
};

export default ReviewAndSubmit;
