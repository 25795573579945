import * as React from 'react';
import { Box, IconButton, Link, Stack, Typography } from '@mui/material';
import { FileUploader } from 'react-drag-drop-files';

import { DocumentVSku, VSkuType } from '@features/endpoint/endpointSlice';

import { useWizard } from '@shared/components';
import { useEffect } from 'react';
import { AnalyticsContext } from '@context/analyticsContext';
import { FormattedMessage, useIntl } from 'react-intl';
import { Close, UploadFileOutlined } from '@mui/icons-material';

const FileIcon = () => {
  return (
    <Box
      sx={{
        backgroundColor: 'background.default',
        borderRadius: '4rem',
        width: '2.5rem',
        height: '2.5rem',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <UploadFileOutlined color='primary' />
    </Box>
  );
};

enum UploadedType {
  none,
  pdf,
  image,
  mixed,
  multiplePdfs,
}

import { defineMessages } from 'react-intl';
// Define Vskus attributes copies
defineMessages({
  multiplePdfsError: {
    id: 'uploadDocument.errors.multiplePdfs',
    description: 'Error message displayed when uploading multiple PDFs',
    defaultMessage: 'Cannot upload more than one PDF per document',
  },
  mixedError: {
    id: 'uploadDocument.errors.mixed',
    description:
      'Error message displayed when uploading a mix of PDFs and images',
    defaultMessage: 'Cannot mix PDF and images while uploading files',
  },
});

const getErrors = (newFileType: UploadedType) => {
  switch (newFileType) {
    case UploadedType.mixed:
      return { file: 'uploadDocument.errors.mixed' };
    case UploadedType.multiplePdfs:
      return { file: 'uploadDocument.errors.multiplePdfs' };
    default:
      return {};
  }
};

const UploadDocument = ({
  vspecId,
  vsku,
  hasNext,
}: {
  vspecId: string;
  vsku: DocumentVSku;
  hasNext?: boolean;
}): JSX.Element => {
  const fileTypes = ['JPG', 'PNG', 'GIF', 'PDF'];
  const [files, setFiles] = React.useState([]);
  const [uploadedType, setUploadedType] = React.useState<UploadedType>(
    UploadedType.none
  );
  const [errors, setErrors] = React.useState<Record<string, string>>({});
  const {
    overrideButtonsController,
    goToNextStep,
    setStepValue,
    activeStep,
    activeStepValue,
  } = useWizard();
  const intl = useIntl();
  const handleSubmit = React.useMemo(
    () => () => {
      // Dummy implementation that does nothing with the "uploaded" files
      console.log('Submitting document upload', files);
      setStepValue({
        vspecId,
        stepType: VSkuType.document,
        vsku: vsku.vsku,
        primaryInputs: [],
        inputValues: Object.fromEntries(
          files.map((file, index) => [`file-${index}`, file])
        ),
        jurisdiction: vsku.jurisdiction,
      });
      // analytics log step
      goToNextStep();
    },
    [files]
  );

  const handleChange = React.useMemo(
    () => (newFiles: { name: string; type: string }[]) => {
      setFiles([...files, ...newFiles]);
    },
    [files]
  );
  useEffect(() => {
    const newFileType = files.reduce((acc, file) => {
      if (file.type === 'application/pdf') {
        if (acc === UploadedType.image) {
          return UploadedType.mixed;
        }
        if (acc === UploadedType.pdf) {
          return UploadedType.multiplePdfs;
        }
        if (acc === UploadedType.none) {
          return UploadedType.pdf;
        }
      }
      if (file.type.startsWith('image/')) {
        if (acc === UploadedType.pdf) {
          return UploadedType.mixed;
        }
        if (acc === UploadedType.none) {
          return UploadedType.image;
        }
      }
      return acc;
    }, UploadedType.none);
    setUploadedType(newFileType);
    setErrors(getErrors(newFileType));
  }, [files]);
  useEffect(() => {
    overrideButtonsController({
      next: {
        isHidden: () => !hasNext,
        onClick: () => handleSubmit(),
      },
      previous: {},
    });
  }, [hasNext, handleSubmit]);

  useEffect(() => {
    if (activeStepValue) {
      const { inputValues } = activeStepValue;
      const savedFiles = Object.entries(inputValues)
        .sort(([keyA], [keyB]) => keyA.localeCompare(keyB))
        .map(([, value]) => value);
      setFiles(savedFiles);
    } else {
      setFiles([]);
    }
  }, [activeStep, activeStepValue]);

  return (
    <Box sx={{ width: '100%' }} component='form' noValidate>
      <FileUploader
        handleChange={handleChange}
        name='file'
        multiple
        types={fileTypes}
        fileOrFiles={files}
        hoverTitle={intl.formatMessage({
          defaultMessage: 'Drop documents here',
          description: 'Message displayed within drag and drop file upload box',
        })}
      >
        <Box
          sx={{
            border: '1px dashed',
            borderRadius: '4px',
            borderColor: 'grey.200',
            padding: '1rem',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <FileIcon />
          <Box sx={{ mt: '1rem', mb: '0.5rem' }}>
            <Link>
              <FormattedMessage
                description='Upload message'
                defaultMessage='Click to upload or drag and drop'
              />
            </Link>
          </Box>
        </Box>
      </FileUploader>
      <Box sx={{ height: '2rem' }}>
        {errors['file'] && (
          <Typography variant='body2' textAlign='center' sx={{ color: 'red' }}>
            {intl.formatMessage({ id: errors['file'] })}
          </Typography>
        )}
      </Box>
      <Stack spacing={1.5}>
        {files.map((file: { name: string }, index) => (
          <Box
            key={`file-${index}`}
            sx={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}
          >
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'flex-start',
                alignItems: 'center',
                overflow: 'hidden',
              }}
            >
              <FileIcon />
              <Typography
                sx={{
                  ml: '0.5rem',
                  textOverflow: 'ellipsis',
                  maxWidth: '85%',
                  whiteSpace: 'nowrap',
                  overflow: 'hidden',
                }}
              >
                {file.name}
              </Typography>
            </Box>
            <IconButton
              color='primary'
              onClick={() => setFiles(files.filter((_, i) => i !== index))}
            >
              <Close />
            </IconButton>
          </Box>
        ))}
      </Stack>
    </Box>
  );
};

export default UploadDocument;
