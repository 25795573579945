import * as React from 'react';
import { useIntl } from 'react-intl';
import { FormControlLabel, Switch } from '@mui/material';

import type { EndpointInput } from '@utils/schema';

const VerifyInputBoolean = ({
  input,
  value,
  setFieldValue,
}: {
  input: EndpointInput;
  value: boolean;
  setFieldValue: (value: boolean) => void;
}): JSX.Element => {
  const intl = useIntl();
  return (
    <FormControlLabel
      control={
        <Switch
          name={input.inputName}
          checked={value}
          onChange={evt => setFieldValue(evt.target.checked)}
        />
      }
      label={intl.formatMessage({ id: input.inputDescription })}
    />
  );
};

export default VerifyInputBoolean;
