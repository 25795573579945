import * as React from 'react';
import { Box, Button, Stack } from '@mui/material';

import { FieldVSku, VSkuType } from '@features/endpoint/endpointSlice';

import VerifyInput from '@shared/components/VerifyInput/VerifyInput';
import type { EndpointInput, EndpointSchema } from '@utils/schema';
import { buildDynamicSchema } from '@utils/schema';
import { useWizard } from '@shared/components';
import { useEffect } from 'react';
import { AnalyticsContext } from '@context/analyticsContext';
import { FormattedMessage, useIntl } from 'react-intl';

const VerifyFields = ({
  vspecId,
  vsku,
  hasNext,
}: {
  vspecId: string;
  vsku: FieldVSku;
  hasNext?: boolean;
}): JSX.Element => {
  const [yupSchema, setYupSchema] = React.useState<EndpointSchema>();
  const [formData, setFormData] = React.useState<Record<string, any>>({});
  const [errors, setErrors] = React.useState<Record<string, string>>({});
  const { logEvent } = React.useContext(AnalyticsContext);
  const {
    overrideButtonsController,
    goToNextStep,
    setStepValue,
    duplicateCurrentStep,
    activeStep,
    activeStepValue,
  } = useWizard();
  const intl = useIntl();
  useEffect(() => {
    overrideButtonsController({
      next: {
        isHidden: () => !hasNext,
        onClick: () => handleSubmit(),
      },
      previous: {},
    });
  }, [yupSchema, formData, hasNext]);

  React.useEffect(() => {
    const fetchSchema = async () => {
      const schema = await buildDynamicSchema(vsku);
      setYupSchema(schema);
      logEvent({
        event: 'display_form',
        properties: { vsku: vsku.vsku },
      });
    };
    fetchSchema();
  }, [vsku]);

  useEffect(() => {
    if (activeStepValue) {
      const { inputValues } = activeStepValue;
      setFormData(inputValues);
    } else {
      setFormData(yupSchema?.defaultData ?? {});
    }
  }, [activeStep, activeStepValue, yupSchema]);

  const schemaFields: EndpointInput[] = React.useMemo(() => {
    if (yupSchema) {
      return yupSchema.getFields(formData);
    }
    return [];
  }, [yupSchema, formData]);

  const setFieldValue = React.useMemo(
    () =>
      ({ name, value }: { name: string; value: any }) => {
        setErrors({});
        setFormData({
          ...formData,
          [name]: value,
        });
      },
    [formData]
  );

  const handleSubmit = () => {
    if (yupSchema) {
      const validationResults = yupSchema.validate(formData);
      if (validationResults.isValid) {
        setStepValue({
          vspecId,
          vsku: vsku.vsku,
          stepType: VSkuType.field,
          primaryInputs: vsku.primaryInputs,
          inputValues: validationResults.values,
          jurisdiction: vsku.jurisdiction,
        });
        goToNextStep();
      } else {
        setErrors(validationResults.errorMap);
      }
    }
  };
  const handleDuplicate = () => {
    if (yupSchema) {
      const validationResults = yupSchema.validate(formData);
      if (validationResults.isValid) {
        setStepValue({
          vspecId,
          vsku: vsku.vsku,
          stepType: VSkuType.field,
          primaryInputs: vsku.primaryInputs,
          inputValues: validationResults.values,
          jurisdiction: vsku.jurisdiction,
        });
        duplicateCurrentStep();
        goToNextStep();
      } else {
        setErrors(validationResults.errorMap);
      }
    }
  };

  return (
    <Box sx={{ width: '100%' }} component='form' noValidate>
      <Stack spacing={1.5}>
        {schemaFields.map(input => (
          <VerifyInput
            key={`field_${input.inputName}`}
            input={input}
            value={formData[input.inputName]}
            setFieldValue={(value: any) =>
              setFieldValue({ name: input.inputName, value })
            }
            error={errors[input.inputName]}
          />
        ))}
      </Stack>
      {vsku.allowMultiples && (
        <Box sx={{ display: 'flex', justifyContent: 'center', marginTop: 2 }}>
          <Button onClick={handleDuplicate}>
            <FormattedMessage
              description='Text to prompt user to add another item to the verificationOrder'
              defaultMessage='Add another {name}'
              values={{
                name: intl.formatMessage({ id: vsku.nameId }),
              }}
            />
          </Button>
        </Box>
      )}
    </Box>
  );
};

export default VerifyFields;
