import * as React from 'react';
import { Box, Typography } from '@mui/material';

import {
  DocumentVSku,
  FieldVSku,
  VSkuType,
  type VspecEndpoint,
} from '@features/endpoint/endpointSlice';

import { FormattedMessage } from 'react-intl';
import VerifyFields from './vskuTypes/VerifyFields';
import UploadDocument from './vskuTypes/UploadDocument';

const VerifyForm = ({
  vspec,
  hasNext,
}: {
  vspec: VspecEndpoint;
  hasNext?: boolean;
}): JSX.Element => {
  // TODO: Cleanup after everything is fully upgraded
  const { vspecId, vsku, type = VSkuType.field } = vspec;
  if (type === VSkuType.field) {
    console.log('rendering field');
    return (
      <VerifyFields
        vspecId={vspecId}
        vsku={vsku as FieldVSku}
        hasNext={hasNext}
      />
    );
  }

  if (type === VSkuType.document) {
    console.log('rendering doc');
    return (
      <UploadDocument
        vspecId={vspecId}
        vsku={vsku as DocumentVSku}
        hasNext={hasNext}
      />
    );
  }
  // Unsupported vsku type
  console.log('Found unsupported vsk');
  return (
    <Box sx={{ width: '100%' }}>
      <Typography>
        <FormattedMessage
          defaultMessage='Unsupported vsku type'
          description='Message to render an unsupported vsku type'
        />
      </Typography>
    </Box>
  );
};

export default VerifyForm;
