import * as React from 'react';
import { Loader } from '@shared/components';
import { createBrowserRouter, Params, RouterProvider } from 'react-router-dom';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';

import { IntlProvider, MessageFormatElement } from 'react-intl';
import { CustomerLayout } from '@shared/layouts';
import { ThemeProvider } from '@mui/material';
import { MeshTheme } from '@shared/themes';
import AppProvider from '@context/app.context';
import AnalyticsProvider from '@context/analyticsContext';
import { EmbeddedTester } from '@features/embeddedTester/EmbeddedTester';
import { Experiment } from '@context/experimentContext';

const Endpoint = React.lazy(() => import('./features/endpoint/Endpoint'));
const Verification = React.lazy(
  () => import('./features/verification/Verification')
);
const NotFound = React.lazy(() => import('./features/not-found/NotFound'));

type Messages = Record<string, string> | Record<string, MessageFormatElement[]>;

const loadLocaleData = (locale: string) => {
  if (locale.startsWith('en')) {
    return import('../compiled-lang/en.json');
  }
  // Fallback locale
  return import('../compiled-lang/en.json');
};

function getVerificationComponent() {
  return (
    <React.Suspense fallback={<Loader />}>
      <Endpoint>
        <CustomerLayout>
          <Verification />
        </CustomerLayout>
      </Endpoint>
    </React.Suspense>
  );
}

const aliasPrefixPaths = ['a', 'alias', 'verify/a', 'verify/alias'];
const aliasRoutes = aliasPrefixPaths.map(alias => ({
  path: `/${alias}/*`,
  loader: ({ params }: { params: Params<string> }) => ({ alias: params['*'] }),
  element: getVerificationComponent(),
}));

const router = createBrowserRouter([
  ...aliasRoutes,
  {
    path: '/verify/:endpoint',
    loader: ({ params }) => ({ endpoint: params.endpoint }),
    element: getVerificationComponent(),
  },
  {
    path: '/tester',
    element: <EmbeddedTester />,
  },
  {
    path: '/*',
    element: <NotFound />,
  },
]);

const App: React.FC = () => {
  const [messages, setMessages] = React.useState<Messages>({});
  const languages = navigator.languages;
  console.log('found languages', languages);
  React.useEffect(() => {
    const loadMessages = async () => {
      const loaded = await loadLocaleData(languages[0]);
      console.log('got messages', loaded);
      // setMessages(loaded)
      setMessages(loaded.default);
    };
    loadMessages();
  }, [navigator.languages]);

  const tellParentClosed = () => {
    if (window.opener) {
      window.opener.postMessage({ event: 'page_closed', data: {} }, '*');
    }
  };
  React.useEffect(() => {
    window.addEventListener('beforeunload', tellParentClosed);
    return () => {
      window.removeEventListener('beforeunload', tellParentClosed);
    };
  });

  return (
    <ThemeProvider theme={MeshTheme}>
      <IntlProvider locale='en' messages={messages} defaultLocale='en'>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <AppProvider>
            <Experiment>
              <AnalyticsProvider>
                <RouterProvider router={router} />
              </AnalyticsProvider>
            </Experiment>
          </AppProvider>
        </LocalizationProvider>
      </IntlProvider>
    </ThemeProvider>
  );
};

export default App;
