import type { Schema, SchemaFieldDescription, ValidationError } from 'yup';
import * as yup from 'yup';
import { PROFESSIONAL_LICENSE_INPUT_1_0_0 } from './schemas/professionalLicenseInput';
import {
  FieldVSku,
  VSkuType,
  VspecEndpoint,
} from '@features/endpoint/endpointSlice';

const { object } = yup;
export interface EndpointValidateResults {
  isValid: boolean;
  values: { [key: string]: string };
  errorMap: Record<string, string>;
}

export interface EndpointSchema {
  validate: (data: Record<string, any>) => EndpointValidateResults;
  getFields: (data: Record<string, any>) => EndpointInput[];
  defaultData: Record<string, any>;
}

export interface EndpointOption {
  label: string;
  value: string;
}

export interface EndpointInput {
  inputName: string;
  inputDescription: string;
  inputHelperText?: string;
  inputType: EndpointType;
  options?: EndpointOption[];
  isRequired: boolean;
}

export enum EndpointType {
  Hidden,
  TextInput,
  DateInput,
  DropdownInput,
  BooleanInput,
}

const getEndpointType = (field: SchemaFieldDescription): EndpointType => {
  if (
    'meta' in field &&
    field.meta &&
    'hidden' in field.meta &&
    field.meta.hidden === true
  ) {
    return EndpointType.Hidden;
  }
  if (field.type === 'boolean') {
    return EndpointType.BooleanInput;
  }
  if (
    'meta' in field &&
    field.meta &&
    'dropdownOptions' in field.meta &&
    Array.isArray(field.meta.dropdownOptions)
  ) {
    return EndpointType.DropdownInput;
  }
  // Default input type
  return EndpointType.TextInput;
};

const buildValidate =
  (schema: Schema) =>
  (data: Record<string, any>): EndpointValidateResults => {
    try {
      console.log('validating data', data);
      const isValid = schema.validateSync(data, { abortEarly: false });
      console.log('isValid', isValid);
      return {
        isValid: true,
        values: isValid,
        errorMap: {},
      };
    } catch (e) {
      const errorMap = Object.fromEntries(
        e.inner.map((err: ValidationError) => [err.path, err.message])
      );
      return {
        isValid: false,
        values: {},
        errorMap,
      };
    }
  };

const buildGetFields =
  (schema: Schema) =>
  (data: Record<string, any>): EndpointInput[] => {
    const description = schema.describe({
      value: data,
    });
    if ('fields' in description && description.fields) {
      const fields = Object.entries(description.fields).map(
        ([inputName, field]) => {
          const inputDescription = 'label' in field ? field.label : 'ref label';
          const options: EndpointOption[] =
            'meta' in field &&
            field.meta &&
            'dropdownOptions' in field.meta &&
            Array.isArray(field.meta.dropdownOptions) &&
            field.meta.dropdownOptions.map(
              ({ value, label }: { value: string; label: string }) => ({
                label,
                value,
              })
            );
          const inputType = getEndpointType(field);
          const isRequired =
            'tests' in field
              ? field.tests.some(
                  ({ name }: { name: string }) => name === 'required'
                )
              : false;
          return {
            inputName,
            inputDescription,
            inputType,
            options,
            isRequired,
          };
        }
      );
      return fields;
    }
    return [];
  };

const buildSchema = (schema: Schema): EndpointSchema => {
  const validate = buildValidate(schema);
  const getFields = buildGetFields(schema);
  const defaultData = schema.cast({});
  console.log('got default', defaultData);

  return {
    validate,
    getFields,
    defaultData,
  };
};

const schemaCache: Record<string, EndpointSchema> = {
  'professionalLicenseInput@1.0.0': buildSchema(
    PROFESSIONAL_LICENSE_INPUT_1_0_0
  ),
};

export const getSchema = async (
  schemaName: string
): Promise<EndpointSchema> => {
  if (schemaName in schemaCache) {
    return schemaCache[schemaName];
  }
  return buildSchema(object({}));
};

export const buildDynamicSchema = async (
  vsku?: FieldVSku
): Promise<EndpointSchema> => {
  if (vsku) {
    const inputClaims = vsku.inputClaims;
    const arrayOfEntries = inputClaims
      .filter(({ isDerived }) => !isDerived)
      .map(claim => {
        let yupType;
        const labelStringId = claim.nameId;
        const requiredError = claim.errorId;
        switch (claim.dataType) {
          case 'string':
            yupType = yup
              .string()
              .label(labelStringId)
              .default('')
              .required(requiredError);
            break;
          case 'enum':
            yupType = yup
              .string()
              .label(labelStringId)
              .default('')
              .meta({
                dropdownOptions: claim.options?.values.map(
                  ({ value, labelStringId }) => ({
                    value,
                    label: labelStringId,
                  })
                ),
              })
              .required(requiredError)
              .oneOf(
                claim.options?.values?.map(item => item?.value),
                requiredError
              );
            break;
          case 'date':
            yupType = yup.date().label(labelStringId).required(requiredError);
            break;
          default:
            yupType = yup
              .string()
              .label(labelStringId)
              .default('')
              .required(requiredError);
            break;
        }
        return [claim.fieldName, yupType];
      });
    const newEntries = Object.fromEntries(arrayOfEntries);
    const yupObject = yup.object().shape(newEntries);
    return buildSchema(yupObject);
  }
  return buildSchema(object({}));
};
