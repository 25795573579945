import { combineReducers } from '@reduxjs/toolkit';
import { reducer } from 'redux-storage';
import authReducer from '../features/verification/steps/auth/authSlice';
import endpointReducer from '../features/endpoint/endpointSlice';
import createVerificationOrderReducer from '../features/verification-order/verificationOrderSlice';

const rootReducer = reducer(
  combineReducers({
    auth: authReducer,
    endpoint: endpointReducer,
    createVerificationOrder: createVerificationOrderReducer,
  })
);
export default rootReducer;
