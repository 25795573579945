import { selectEndpoint } from '@features/endpoint/endpointSlice';
import { useAppSelector } from '@hooks/useAppSelector';
import { MeshFeedback, useWizard } from '@shared/components';

import React, { useContext, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { selectAuthentication } from '../auth/authSlice';
import { AppContext } from '@context/app.context';
import { FormattedMessage } from 'react-intl';
import {
  Box,
  Button,
  Card,
  CardContent,
  Link,
  Typography,
} from '@mui/material';
import Mesh from '@shared/icons/Mesh';
import HowToRegOutlinedIcon from '@mui/icons-material/HowToRegOutlined';
import VisibilityOffOutlinedIcon from '@mui/icons-material/VisibilityOffOutlined';
import HttpsOutlinedIcon from '@mui/icons-material/HttpsOutlined';
const BULLET_POINTS = [
  {
    header: (
      <FormattedMessage
        description='Verify your identity bullet point header'
        defaultMessage='Verify your identity'
      />
    ),
    body: (
      <FormattedMessage
        description='Verify your identity bullet point body'
        defaultMessage='Proving your business identity with Mesh is quick and easy'
      />
    ),
    icon: <HowToRegOutlinedIcon color='primary' />,
  },
  {
    header: (
      <FormattedMessage
        description='Your data belongs to you bullet point header'
        defaultMessage='Your data belongs to you'
      />
    ),
    body: (
      <FormattedMessage
        description='Your data belongs to you bullet point body'
        defaultMessage="Mesh doesn't sell data to advertisers, and only shares it with your permission"
      />
    ),
    icon: <VisibilityOffOutlinedIcon color='primary' />,
  },
  {
    header: (
      <FormattedMessage
        description='Your data is secure bullet point header'
        defaultMessage='Your data is secure'
      />
    ),
    body: (
      <FormattedMessage
        description='Your data is secure bullet point body'
        defaultMessage='We secure your information with bank-level 256-bit encryption.'
      />
    ),
    icon: <HttpsOutlinedIcon color='primary' />,
  },
];

const Welcome = ({ acceptTerms }: { acceptTerms: () => void }) => {
  const endpointConfig = useAppSelector(selectEndpoint);
  const { customerLogo, customerName } = endpointConfig.customer;

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        height: '100%',
      }}
    >
      <Card
        sx={{
          backgroundColor: 'white',
          height: '100%',
          width: '100%',
        }}
      >
        <CardContent
          sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
            gap: 2,
            height: '100%',
          }}
        >
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
            }}
          >
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'center',
                pt: '2rem',
              }}
            >
              <img src={customerLogo} height={30} />
              <Box sx={{ height: '30px', width: '108px', pl: '1rem' }}>
                <Mesh />
              </Box>
            </Box>
            <Typography
              variant='h3'
              textAlign='center'
              color='emphasis.main'
              sx={{ pt: '4rem' }}
            >
              <FormattedMessage
                description='Welcome header'
                defaultMessage='{customerName} uses Mesh to verify and onboard its business partners.'
                values={{
                  customerName,
                }}
              />
            </Typography>
            {BULLET_POINTS.map((bulletPoint, index) => (
              <Box
                key={`bullet-point-${index}`}
                sx={{
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'flex-start',
                  pt: '2.5rem',
                }}
              >
                {bulletPoint.icon}
                <Box
                  sx={{ display: 'flex', flexDirection: 'column', pl: '1rem' }}
                >
                  <Typography variant='h5'>{bulletPoint.header}</Typography>
                  <Typography variant='body1'>{bulletPoint.body}</Typography>
                </Box>
              </Box>
            ))}
          </Box>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
            }}
          >
            <Typography
              variant='caption'
              textAlign='center'
              color='text.secondary'
            >
              <FormattedMessage
                description='Legal disclaimer for submitting form'
                defaultMessage="By continuing, I agree to being verified under Mesh's <termsOfUse>Terms of Use</termsOfUse> and <privacy>Privacy Statement</privacy>, and consent to Mesh contacting me with information relating to my verification status."
                values={{
                  privacy: val => (
                    <Link
                      target='_blank'
                      href='https://www.mesh.id/privacy-statement'
                    >
                      {val}
                    </Link>
                  ),
                  termsOfUse: val => (
                    <Link
                      target='_blank'
                      href='https://www.mesh.id/terms-of-service'
                    >
                      {val}
                    </Link>
                  ),
                }}
              />
            </Typography>
            <Button
              variant='contained'
              color='emphasis'
              fullWidth
              onClick={acceptTerms}
              size='large'
            >
              <FormattedMessage
                description='Button text to accept the terms and conditions'
                defaultMessage='Next'
              />
            </Button>

            <Box
              sx={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                justifyContent: 'center',
                paddingTop: '0.5rem',
              }}
            >
              <Typography
                sx={{
                  pr: 1,
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                }}
                variant='subtitle2'
              >
                <FormattedMessage
                  description='Wizard footer verifications powered by'
                  defaultMessage='Verifications powered by {logo}'
                  values={{
                    logo: (
                      <Box
                        sx={{
                          height: '1.5rem',
                          marginLeft: '0.5rem',
                          display: 'inline-block',
                        }}
                      >
                        <Box sx={{ height: '15px', width: '55px' }}>
                          <Mesh />
                        </Box>
                      </Box>
                    ),
                  }}
                />
              </Typography>
            </Box>
          </Box>
        </CardContent>
      </Card>
    </Box>
  );
};

export default Welcome;
