import * as React from 'react';
import { useIntl } from 'react-intl';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';

import type { EndpointInput } from '@utils/schema';
import { MeshLabel } from '@shared/components';
import { useMemo } from 'react';

const VerifyInputDate = ({
  input,
  value = '',
  error,
  setFieldValue,
}: {
  input: EndpointInput;
  value: string;
  error: string;
  setFieldValue: (value: string) => void;
}): JSX.Element => {
  const intl = useIntl();
  const helperText = useMemo(() => {
    if (error) {
      return intl.formatMessage({ id: error });
    }
    if (input.inputHelperText) {
      return intl.formatMessage({ id: input.inputHelperText });
    }
    return '';
  }, [input, error]);
  return (
    <MeshLabel
      label={intl.formatMessage({ id: input.inputDescription })}
      required={input.isRequired}
    >
      <DatePicker
        value={value}
        onChange={value => setFieldValue(value)}
        slotProps={{
          textField: {
            size: 'small',
            required: input.isRequired,
            error: !!error,
            helperText: helperText,
          },
        }}
      />
    </MeshLabel>
  );
};

export default VerifyInputDate;
