// This config is generated - please do not edit it in any way.
// Check scripts/generateConfig.js if you need to modify it in any way
interface Config {
  stage: string;
  cognitoConfig: {
    UserPoolId: string;
    ClientId: string;
  };
  appSync: {
    endpoint: string;
    apiKey: string;
  };
  userSnapApiKey: string;
  amplitudeApiKey: string;
  splitApiKey: string;
}
const config: Config = JSON.parse(
  '{"stage":"devArthur","cognitoConfig":{"UserPoolId":"us-east-1_XrcFJS8UT","ClientId":"71a9sjes6bhca10ul1heop98nm"},"appSync":{"endpoint":"https://mesh-professional-frontend-app-devarthur.mesh.id/graphql","apiKey":"da2-eszfbluebjezvms4jjvvflctb4"},"userSnapApiKey":"0f1873d5-b9f3-49dc-8586-3fe71c78c6a1","amplitudeApiKey":"6fa24fe4f01b49d1e34187ee202974bf","splitApiKey":"b34blo97ebpscob1opdp84eos7ltje2p9h8h"}'
);
export default config;
